import React, { useState } from 'react'
import CInput from '../../components/common/CInput'
import { Theme } from '../../Theme'
import CTable from '../../components/common/CTable'
import { ColumnsType } from 'antd/lib/table'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CustomAlert from '../../components/common/CAlert'
import { activeDeactivePackage, getAllPackage } from '../../services/service'
import _ from 'lodash'
import AlertModal from '../../components/common/AlertModal'
import { Switch } from 'antd'
import CButton from '../../components/common/CButton'
import EmptyList from '../../components/common/EmptyList'
interface PackagesListProps {
    serviceDetail: any;  // Use `any` or the specific type expected for serviceDetail
  }
const PackagesList: React.FC<PackagesListProps> = ({ serviceDetail }) => {
  const [userWillActive, setUserWillActive] = useState<{
    show: boolean
    status: boolean
    id: number | null
  }>({
    show: false,
    status: false,
    id: null
  })
  const [alert, setAlert] = useState<{
    message: string
    type: 'success' | 'error'
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  console.log('serviceDetail===>>>', serviceDetail)
  const pageNumber = Number(searchParams.get('PageNumber')) || 1
  const pageSize = Number(searchParams.get('PageSize')) || 10
  const search = searchParams.get('Search') || ''

  // Pagination handling
  const handleTableChange = (page: number, pageSize: number) => {
    setSearchParams(
      { ageNumber: page.toString(), PageSize: pageSize.toString() },
      { replace: true }
    )
  }

  const handleSearchChange = _.debounce((e: any) => {
    setSearchParams(
      {
        PageNumber: '1',
        PageSize: pageSize.toString(),
        Search: e.target.value
      },
      { replace: true }
    )
  }, 1000)

  const handleSwitchChange = (user: any) => {
    setUserWillActive({ show: true, status: user?.active, id: user?.id })
  }

  const columns: ColumnsType = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: t('Package Name'),
      dataIndex: 'packageName',
      key: 'packageName'
    },
    {
      title: t('Service Name'),
      dataIndex: 'serviceName',
      key: 'serviceName'
    },
    {
      title: t('Package Price'),
      dataIndex: 'packagePrice',
      key: 'packagePrice'
    },
    {
      title: t('Category'),
      dataIndex: 'categoryName',
      key: 'categoryName'
    },
    // {
    //   title: t('Location'),
    //   dataIndex: 'serviceLocation',
    //   key: 'serviceLocation'
    // },
    // {
    //     title: t("Number of Packages"),
    //     dataIndex: 'serviceCount',
    //     key: 'serviceCount',
    // },
    {
      title: t('action'),
      render: (text, record) => (
        <div className='d-flex  align-items-center'>
          <img
            src={Theme.icons.icn_view_list}
            alt='icn_user_add'
            style={{ height: '20px',cursor:'pointer' }}
            onClick={() => navigate(`/jive/viewPackage/${record?.id}`)}
          />
         {record.status == 'Approved' && (
            <Switch
            checked={record?.active}
            onChange={() => handleSwitchChange(record)}
            className='mx-2'
          />
         )} 
        </div>
      ),
      key: 'action'
    }
  ]

  const { data, error, isLoading } = useQuery(
    ['AllPackages', pageNumber, pageSize, search],
    () =>
        getAllPackage({
        serviceId: params?.id,
        PageNumber: pageNumber,
        PageSize: pageSize,
        Search: search
      }),
    {
      onError: (err: any) => {
        setAlert({
          message: err?.message || t('An error occurred'),
          type: 'error',
          visible: true
        })
      }
    }
  )

  const { mutate, isLoading: statusLoading } = useMutation(
    activeDeactivePackage,
    {
      onMutate: async (newStatus: any) => {
        await queryClient.cancelQueries(['AllPackages'])
        const previousData = queryClient.getQueryData(['AllPackages'])
        return { previousData }
      },
      onError: (err: any, newStatus, context) => {
        queryClient.setQueryData(['AllPackages'], context?.previousData)
        setAlert({
          message: err?.message || t('Failed to update status'),
          type: 'error',
          visible: true
        })
      },
      onSettled: () => {
        queryClient.invalidateQueries(['AllPackages'])
      },
      onSuccess: () => {
        setAlert({
          message: t('Status Updated successfully!'),
          type: 'success',
          visible: true
        })
      }
    }
  )

  const handleModalOk = () => {
    if (userWillActive.id !== null) {
      const newStatus = !userWillActive.status
      console.log('max Val==+>>>', userWillActive)
      mutate({ id: userWillActive.id, Active: newStatus })
    }
    setUserWillActive({ show: false, status: false, id: null })
  }

  const handleModalCancel = () => {
    setUserWillActive({ show: false, status: false, id: null })
  }

  const isEmpty = !data?.data?.length && !data?.totalRecords
  const redirectUrl = `/jive/createUpdatePackage`

  return (
    <>
      {isEmpty ? (
        <>
          <div
            style={{
              backgroundColor: Theme.colors.tabsColor,
              borderRadius: '10px'
            }}
          >
            <EmptyList
              heading={t('No Package Created Yet!')}
              description={t('There are no packages available.')}
              buttonLabel={t('Create New Package')}
              icon={
                <img
                  alt=''
                  src={Theme.icons.icn_add_btn}
                  style={{ height: '20px' }}
                  className='icon-white'
                />
              }
              onButtonClick={() => navigate(`${redirectUrl}`,{state : {serviceDetails : serviceDetail , record: undefined,formType : 'create'}})}
            />
          </div>
        </>
      ) : (
        <>
          <div className='d-flex justify-content-end align-items-center'>
            {/* <p className="m-0 list-heading">{`${activeKey} List`}</p> */}
            <div className='d-flex justify-content-between'>
              <CInput
                prefix={
                  <img
                    alt=''
                    src={Theme.icons.icn_search}
                    style={{ height: '16px' }}
                  />
                }
                className='searchInput'
                placeholder={t('Search')}
                onChange={handleSearchChange}
              />
              <CButton
                className='addUserBtn'
                onClick={() => navigate(`${redirectUrl}`,{state : {serviceDetails : serviceDetail , record: undefined,formType : 'create'}})}
              >
                <img
                  src={Theme.icons.icn_add_service}
                  alt='icn_user_add'
                  style={{ height: '20px' }}
                />
                <p className='m-0'>{t('Create New Package')}</p>
              </CButton>
            </div>
          </div>
          <CTable
            footer='End of Table'
            columns={columns}
            dataSource={data?.data}
            pagination={{
              showSizeChanger: false,
              showQuickJumper: false,
              current: pageNumber,
              pageSize: pageSize,
              total: data?.totalRecords,
              onChange: handleTableChange,
              showTotal: (total: number, range: [number, number]) => (
                <p>{`Showing ${range[0]} - ${range[1]} of ${total} Results`}</p>
              ),
              itemRender: (page, type, originalElement) => {
                if (type === 'prev') {
                  return <p className='detail-label'>{`< Prev`}</p>
                }
                if (type === 'next') {
                  return <p className='detail-label'>{`Next >`}</p>
                }
                return originalElement
              }
            }}
            loading={isLoading}
          />
        </>
      )}

      <AlertModal
        visible={userWillActive?.show}
        message={t(
          `Are you sure you want to ${
            userWillActive?.status ? 'deactivate' : 'activate'
          } this Package?`
        )}
        onDelete={handleModalOk}
        onCancel={handleModalCancel}
      />
      <CustomAlert
        message={alert.message}
        type={alert.type}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </>
  )
}

export default PackagesList
