import instance from "./api/api_instance";

export const getAllFreelancer = async (credentials: object) => {
  const response = await instance.post(
    "AdminVendorFreelancer/GetAllCreatedByFreelancers",
    {},
    {
      params: credentials,
    }
  );
  return response.data;
};


export const getAllVendor = async (credentials: object) => {
  const response = await instance.post(
    "AdminVendorFreelancer/GetAllCreatedByVendors",
    {},
    {
      params: credentials,
    }
  );
  return response.data;
};


export const getAllVendors = async (credentials: object) => {
  const response = await instance.post(
    "AdminVendorFreelancer/GetAllVendors",
    {},
    {
      params: credentials,
    }
  );
  return response.data;
};

export const getVendorFreelancerById = async (credentials: object) => {
  const response = await instance.get("AdminVendorFreelancer/GetById", {
    params: credentials,
  });
  return response.data;
};

export const AddUpdateVendorFreelancer = async (credentials: object) => {
  const response = await instance.post(
    "AdminVendorFreelancer/AddUpdateVendorFreelancer",
    credentials
  );
  return response.data;
};

export const SaveServiceDetailInfo = async (credentials: FormData) => {
  const response = await instance.post(
    "AdminVendorFreelancer/SaveServiceDetailInfo",
    credentials,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};


export const SaveServiceInfo = async (credentials: object) => {
  const response = await instance.post(
    "AdminVendorFreelancer/SaveProfileTechnicalInformation",
    credentials
  );
  return response.data;
};

export const SavePortfolioExperienceInfo = async (credentials: object) => {
  const response = await instance.post(
    "AdminVendorFreelancer/SavePortfolioExperienceInfo",
    credentials
  );
  return response.data;
};

export const SaveAvailabilityBookingDetailInfo = async (credentials: object) => {
  const response = await instance.post(
    "AdminVendorFreelancer/SaveAvailabilityBookingDetailInfo",
    credentials
  );
  return response.data;
};


export const SaveCertificationLincensesInfo = async (credentials: FormData) => {
  const response = await instance.post(
    "AdminVendorFreelancer/SaveCertificationLincensesInfo",
    credentials,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};


export const SavePaymentDetailInfo = async (credentials: object) => {
  const response = await instance.post(
    "AdminVendorFreelancer/SavePaymentDetailInfo",
    credentials
  );
  return response.data;
};

export const SaveSupportCommunicationInfo = async (credentials: object) => {
  const response = await instance.post(
    "AdminVendorFreelancer/SaveSupportCommunicationInfo",
    credentials
  );
  return response.data;
};

export const SaveSubmmitedInfo = async (credentials: object) => {
  const response = await instance.post(
    "AdminVendorFreelancer/SaveSubmmitedInfo",
    credentials
  );
  return response.data;
};


export const changeVendorStatus = async (credentials: object) => {
  const response = await instance.post('AdminVendorFreelancer/ChangeVendorStatus', credentials);
  return response.data;
};

export const getAllApprovedVendorFreelancer = async (credentials: object) => {
  const response = await instance.post(
    "AdminVendorFreelancer/GetAllApprovedVendorFreelancers",
    {},
    {
      params: credentials,
    }
  );
  return response.data;
};


export const createMeetingLink = async (credentials: object) => {
  const response = await instance.post('AdminVendorFreelancer/CreateMeetingLink', credentials);
  return response.data;
};

export const initialApprovedVendor = async (credentials: object) => {
  const response = await instance.post('AdminVendorFreelancer/InitialApprovedVendor', credentials);
  return response.data;
};

export const rejectVendor = async (credentials: object) => {
  const response = await instance.post('AdminVendorFreelancer/RejectVendor', credentials);
  return response.data;
};

export const finalApproval = async (credentials: object) => {
  const response = await instance.post(
    "AdminVendorFreelancer/FinalApproved",
    {},
    {
      params: credentials,
    }
  );
  return response.data;
};