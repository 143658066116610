import React, { useState, useEffect } from 'react'
import CInput from '../../../../components/common/CInput'
import { Theme } from '../../../../Theme'
import CTable from '../../../../components/common/CTable'
import { ColumnsType } from 'antd/lib/table'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Switch, Tag } from 'antd'
import {
  changeCustomerStatus,
  getAllCustomers
} from '../../../../services/customer'
import AlertModal from '../../../../components/common/AlertModal'
import CustomAlert from '../../../../components/common/CAlert'
import _ from 'lodash'
import CButton from '../../../../components/common/CButton'
import {
  getAllCities,
  getAllCountries,
  getAllEvents
} from '../../../../services/lov'
import EmptyList from '../../../../components/common/EmptyList'

const CountryList: React.FC = () => {
  const [alert, setAlert] = useState<{
    message: string
    type: 'success' | 'error'
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const pageNumber = Number(searchParams.get('PageNumber')) || 1
  const pageSize = Number(searchParams.get('PageSize')) || 10
  const search = searchParams.get('Search') || ''

  const handleTableChange = (page: number, pageSize: number) => {
    setSearchParams(
      {
        // Status: activeKey,
        PageNumber: page.toString(),
        PageSize: pageSize.toString()
      },
      { replace: true }
    )
  }

  const handleSearchChange = _.debounce((e: any) => {
    setSearchParams(
      {
        // Status: activeKey,
        PageNumber: '1',
        PageSize: pageSize.toString(),
        Search: e.target.value
      },
      { replace: true }
    )
  }, 1000)

  const columns: ColumnsType = [
    {
      title: t('id'),
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: t('country_name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('action'),
      render: (text, record) => (
        <div className='d-flex  align-items-center'>
          <img
            src={Theme.icons.icn_view_list}
            alt='icn_user_edit'
            style={{ height: '20px' }}
            onClick={() => navigate(`${redirectUrl}/view/${record.id}`, { state: { record } })}
          />
        </div>
      ),
      key: 'action'
    }
  ]

  const { data, error, isLoading } = useQuery(
    ['Countries', pageNumber, pageSize, search],
    () =>
      getAllCountries({
        PageNumber: pageNumber,
        PageSize: pageSize,
        Search: search
      }),
    {
      keepPreviousData: true,
      onError: (err: any) => {
        setAlert({
          message: err?.message || t('An error occurred'),
          type: 'error',
          visible: true
        })
      }
    }
  )
  const isEmpty = !data?.data?.length && !data?.totalRecords
  const redirectUrl = '/jive/country'
  return (
    <>
      {isEmpty ? (
        <EmptyList
          heading={t('No Country Created yet!')}
          description={t('There are no countries available.')}
          buttonLabel={t('Add New Country')}
          icon={
            <img
              alt=''
              src={Theme.icons.icn_add_btn}
              style={{ height: '20px' }}
              className='icon-white'
            />
          }
          onButtonClick={() => navigate(`${redirectUrl}/add/new`)}
        />
      ) : (
        <>
          <div className='d-flex justify-content-between align-items-center'>
            <p className='m-0 list-heading'>{t('All Countries List')}</p>
            <div className='d-flex justify-content-between'>
              <CInput
                prefix={
                  <img
                    alt=''
                    src={Theme.icons.icn_search}
                    style={{ height: '16px' }}
                  />
                }
                className='searchInput'
                placeholder={t('Search')}
                onChange={handleSearchChange}
              />
              {/* <CButton
                className='addUserBtn'
                onClick={() => navigate(`${redirectUrl}/add/new`)}
              >
                <img
                  src={Theme.icons.icn_user_add}
                  alt='icn_user_add'
                  style={{ height: '20px' }}
                />
                <p className='m-0'>{t('Add New Country')}</p>
              </CButton> */}
            </div>
          </div>
          <CTable
            footer='End of Table'
            columns={columns}
            dataSource={data?.data}
            pagination={{
              showPrevNextJumpers: true,
              current: pageNumber,
              pageSize: pageSize,
              total: data?.totalRecords,
              onChange: handleTableChange,
              showTotal: (total: number, range: [number, number]) => (
                <p>{`Showing ${range[0]} - ${range[1]} of ${total} Results`}</p>
              ),
              itemRender: (page, type, originalElement) => {
                if (type === 'prev') {
                  return <p className='detail-label'>{`< Prev`}</p>
                }
                if (type === 'next') {
                  return <p className='detail-label'>{`Next >`}</p>
                }
                return originalElement
              }
            }}
            loading={isLoading}
          />
        </>
      )}
      <CustomAlert
        message={alert.message}
        type={alert.type}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </>
  )
}

export default CountryList
