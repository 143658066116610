import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Theme } from '../../../Theme'
import { get } from '../../../services/booking'
import { useMutation, useQuery } from 'react-query'
import { t } from 'i18next'
import Status from '../../../components/common/Status'
import dayjs from 'dayjs'
import CButton from '../../../components/common/CButton'
import CustomAlert from '../../../components/common/CAlert'
import AlertModal from '../../../components/common/AlertModal'
import { getTheFirstTwoLetterOfString } from '../../../utils/functions'

const CustomEventDetails: React.FC = () => {
  const navigate = useNavigate()
  const { type, id } = useParams()

  const [customEventDetails, setCustomEventDetails] = useState<{
    [key: string]: any
  }>({})

  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })

  const {
    mutate: fetchCustomEventDetails,
    isLoading: isFetchingCustomEventDetails
  } = useMutation(
    (id: any) => get('AdminCustomEvent/GetCustomEventById', { id: id }),
    {
      onSuccess: response => {
        if (response.succeeded) {
          setCustomEventDetails(response.data)
        } else {
          setAlert({ message: response.message, type: 'error', visible: true })
        }
      },
      onError: (error: any) => {
        setAlert({
          message: error?.message || 'An error occurred. Please try again.',
          type: 'error',
          visible: true
        })
      }
    }
  )

  useEffect(() => {
    if (type !== 'add' && id !== 'new') {
      fetchCustomEventDetails(id)
    }
  }, [type, id, fetchCustomEventDetails])
  
  const [isModalVisible, setIsModalVisible] = useState(false);
    const [switchChecked, setSwitchChecked] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);

    const { data: statusData, isLoading: statusLoading, error: statusError, refetch } = useQuery(
        ['statusServiceOrder', id],
        () => switchChecked && get(`AdminCustomEvent/${switchChecked}`,{ customEventId: id }),
        {
            enabled: false,
            onSuccess: (data) => {
                setIsSuccess(true);
                setAlert({ message: data.message, type: 'success', visible: true })

            },
        }
    );

    const handleModalOk = async () => {
     await refetch();
     await fetchCustomEventDetails(id)
      setIsModalVisible(false);
      
  };

  const handleModalCancel = () => {
      setIsModalVisible(false);
  };

  const callRequest = (val: string) => {
    setSwitchChecked(val);
    setIsModalVisible(true);
    console.log("Value",val);
    
}

  return (
    <>
      <div style={{ padding: '20px 0px', color: Theme.colors.black }}>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <img
              src={Theme.icons.icn_arrow_back}
              className='cursor-pointer'
              alt='icn_user_add'
              style={{ height: '20px' }}
              onClick={() => navigate(-1)}
            />
            <p className='list-heading m-0 mx-2'>{t('Custom Event Booking')}</p>
          </div>
          {customEventDetails?.status == 'Pending' && (
            <div className='btn-reject sf-medium' onClick={() =>callRequest('Reject')} >{t('Reject')}</div>
          )}

            {customEventDetails?.status == 'VendorApproved' && id !='new' &&
            <div className='d-flex align-items-center w-25'>
                <CButton style={{ marginRight: '12px', backgroundColor: Theme.colors.success, borderColor: Theme.colors.success, width: '100%' }} onClick={() => callRequest('Approved')}>Accept</CButton>
                <CButton className='rejectBtn' style={{ colo: 'red' }} onClick={() => callRequest('Reject')}>Reject</CButton>
            </div>}
          
        </div>
        {customEventDetails?.status == 'Pending' && (
          <div className='d-flex justify-content-end mt-3'>
            <CButton
              className='addUserBtn'
              onClick={() => navigate(`/jive/customEvent/${id}/booking/add/new`, { state: { record: customEventDetails } })}
            >
              <img
                src={Theme.icons.icn_add_service}
                alt='icn_manage_booking'
                style={{ height: '20px' }}
              />
              <p className='m-0'>{t('Create Booking')}</p>
            </CButton>
          </div>
        )}
      </div>

      {type == 'view' && id != 'add' && (
        <>
          {customEventDetails?.status == 'Approved' && (
            <div
              className='mb-3'
              style={{
                backgroundColor: Theme.colors.white,
                padding: '24px',
                borderRadius: '16px',
                display: 'flex',
                gap: '20px',
                alignItems: 'center'
              }}
            >
              <p className='list-heading m-0'>{t('Custom Booking Request')}</p>

              <p className='m-0'>
                <Status status={customEventDetails?.status} />
              </p>
            </div>
          )}

          <div
            style={{
              backgroundColor: Theme.colors.white,
              padding: '24px',
              borderRadius: '16px'
            }}
          >
            <p className='list-heading m-0 mx-2'>{t('Custom Event Details')}</p>

            <div className='view-container mt-3'>
              <div className='d-flex align-items-center'>
                <div className='bold-name-circle'>
                  <p className='m-0'>{getTheFirstTwoLetterOfString(customEventDetails?.userName)}</p>
                </div>
                <p className='user-name m-0 mx-2'>
                  {customEventDetails?.userName}
                </p>
              </div>
              <div
                className='view-container-content mt-3'
                style={{ width: '70%' }}
              >
                <div>
                  <p className='detail-label m-0'>Date</p>
                  <p className='detail-value m-0'>
                    {dayjs(customEventDetails?.eventDate)?.format('DD-MM-YYYY')}
                  </p>
                </div>
                <div>
                  <p className='detail-label m-0'>Time </p>
                  <p className='detail-value m-0'>
                    {customEventDetails?.eventStartTime} -{' '}
                    {customEventDetails?.eventEndTime}
                  </p>
                </div>
                <div>
                  <p className='detail-label m-0'>No of Attendees </p>
                  <p className='detail-value m-0'>
                    {customEventDetails?.noOfAttendees}
                  </p>
                </div>
              </div>
              <div className='view-container-content mt-3'>
                <div>
                  <p className='detail-label m-0'>Location </p>
                  <p className='detail-value m-0'>
                    {customEventDetails?.userAddress}
                  </p>
                </div>
              </div>
              {customEventDetails.noteAndSpecification && (
                <div className='view-container-content mt-3'>
                  <div>
                    <p className='detail-label m-0'>Note And Specification </p>
                    <p className='detail-value m-0'>
                      {customEventDetails?.noteAndSpecification}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          {customEventDetails?.serviceOrders?.length > 0 &&
            customEventDetails.serviceOrders.map((vendor: any, index: any) => (
              <div
                key={index} // Make sure to add a unique key for each item
                className='mt-3'
                style={{
                  backgroundColor: Theme.colors.white,
                  padding: '24px',
                  borderRadius: '16px'
                }}
              >
                <p className='list-heading m-0 mx-2'>
                  {t('Booking Details')} {index + 1}
                </p>

                <div className='view-container mt-3'>
                  <div className='row'>
                    <div className='col-lg-2'>
                      <p className='detail-label m-0'>Vendor Name</p>
                      <p className='detail-value m-0'>{vendor.vendorName}</p>
                    </div>
                    <div className='col-lg-5'>
                      <p className='detail-label m-0'>Service</p>
                      <p className='detail-value m-0'>{vendor?.description}</p>
                    </div>
                    <div className='col-lg-2'>
                      <p className='detail-label m-0'>Date</p>
                      <p className='detail-value m-0'>
                        {dayjs(vendor?.serviceRequestDate)?.format(
                          'DD.MM.YYYY'
                        )}
                      </p>
                    </div>
                    <div className='col-lg-2 d-flex'>
                      {/* <p className='detail-label m-0'>Status</p> */}
                      <p className='m-0'>
                        <Status status={vendor.status} />
                      </p>
                    </div>
                    <div className='col-lg-1 d-flex'>
                      <img
                        src={Theme.icons.icn_view_list}
                        alt='icn_user_add'
                        style={{ height: '20px', cursor: 'pointer' }}
                        onClick={() => navigate(`/jive/customEvent/${id}/booking/view/${vendor.id}`,{state:{record:customEventDetails}})}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}

         {customEventDetails?.serviceOrders?.length > 0 && customEventDetails?.status == "Pending" && (
          <CButton onClick={() =>callRequest('Submitted')} className='mt-3' style={{ width: 'max-content' }}>
                                              Submit
                                          </CButton>
         )} 
        </>
      )}

      <AlertModal
                visible={isModalVisible}
                message={`Are you sure you want to ${switchChecked === 'Submitted' ? "Submit" :  switchChecked === 'Approved' ? 'Approve' : 'Reject'} the request?`}
                onDelete={handleModalOk}
                onCancel={handleModalCancel}
            />

      <CustomAlert
        message={alert.message}
        type={alert.type as 'success' | 'info' | 'warning' | 'error'}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </>
  )
}

export default CustomEventDetails
