import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col, Switch, Card, Modal, message, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import CButton from '../../../../../components/common/CButton';
import { useMutation, useQuery } from 'react-query';
import { Theme } from '../../../../../Theme';
import CustomAlert from '../../../../../components/common/CAlert';
import { getAllParentCategory } from '../../../../../services/lov';
import { SaveServiceInfo } from '../../../../../services/freelancer';
import { useNavigate } from 'react-router-dom';



interface VendorInformationProps {
    moveTo: (e: any) => void;
    state: any;
    isVendorDetailNotEmpty: boolean;
    selectedTab:number;
    maxVisitedTab: number;
}

const ServiceOffering: React.FC<VendorInformationProps> = ({ moveTo, state, isVendorDetailNotEmpty, selectedTab, maxVisitedTab }: any) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });
    const navigate = useNavigate();
    const [exit, setExit] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState<any[]>([]);

    console.log('selected Categories===>>>', selectedCategories);

    const { data: categoriesData, error: countryError, isLoading: isCategoriesLoading } = useQuery('categories', getAllParentCategory);




    const checkCategories = (id: any) => {
        if (selectedCategories.includes(id)) {
            setSelectedCategories(selectedCategories.filter(x => x !== id));
        } else {
            setSelectedCategories([...selectedCategories, id]);
        }

    }

    useEffect(() => {
        if (isVendorDetailNotEmpty) {
            if (state?.serviceTypeId !== null) {
                setSelectedCategories(state?.serviceTypeId?.split(',').map(Number));
            }
        }
    }, [])



    form.setFieldsValue({
        categories: selectedCategories,
    });




    const { mutate, isLoading } = useMutation(SaveServiceInfo, {
        onSuccess: (response) => {
            if (response.succeeded) {
                if (exit) {
                    navigate('/jive/vendorList');
                } else {
                    moveTo(response.data);
                }
            } else {
                setAlert({ message: response.message, type: 'error', visible: true });
            }
        },
        onError: (error: any) => {
            setAlert({ message: error?.message || 'An error occurred. Please try again.', type: 'error', visible: true });
        },
    });

    const handleSave = (values: any) => {
        const obj = {
            id: state.id,
            serviceTypeId: values?.categories?.toString(),
            tabNumber: selectedTab < maxVisitedTab ? maxVisitedTab : selectedTab
        }
        mutate(obj);
    }

    interface Category {
        id: number;
        name: string;
        icon: string; // assuming an icon or image source is passed here
    }



    const handleSaveAndExit = () => {
        setExit(true);
        form.submit();
    };


    return (
        <>
            <p className='user-name mx-3'>{t("Service Offerings")}</p>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSave}
                initialValues={{ remember: true }}
            >
                {isCategoriesLoading ? (
                    <div className='d-flex align-items-center justify-content-center' style={{ height: '200px' }} >
                        <Spin />
                    </div>
                ) : (

                    <Form.Item
                        name="categories"
                        rules={[{ required: true, message: 'Please select your category' }]}
                    >
                        <div className="service-offerings d-flex">
                            <div className="category-grid col-lg-6">
                                {categoriesData?.data?.map((category: any) => (
                                    <div key={category.id} className="category-card" style={{ border: selectedCategories.includes(category.id) ? '1px solid black' : '' }} onClick={() => checkCategories(category.id)}>
                                        <img src={process.env.REACT_APP_S3_BUCKET_URL + category.imagePath} alt={category.name} className="category-icon" />
                                        <p>{category.name}</p>
                                        <img src={Theme.icons.icn_tick} alt={category.name} className="checkmark"
                                            style={{
                                                filter: selectedCategories.includes(category.id) ? 'invert(64%) sepia(95%) saturate(678%) hue-rotate(93deg) brightness(101%) contrast(92%)'
                                                    : 'none'
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Form.Item>

                )}
                <Row gutter={8} style={{ marginTop: '15px' }}>
                    <Col span={4}>
                        <CButton className='cancelBtn' loading={isLoading && exit} onClick={handleSaveAndExit}>
                        {t("Save & Exit")}
                        </CButton>
                    </Col>
                    <Col span={4}>
                        <CButton loading={isLoading && !exit} style={{ width: '100%' }} htmlType="submit">
                        {t("Next")}
                        </CButton>
                    </Col>
                </Row>
            </Form>

            <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />

        </>
    );
};

export default ServiceOffering;
