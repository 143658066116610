import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col, Switch, Card, Modal, Checkbox, CheckboxProps } from 'antd';
import { useTranslation } from 'react-i18next';
import CButton from '../../../../../components/common/CButton';
import { useMutation, useQuery } from 'react-query';
import { SaveSubmmitedInfo, SaveSupportCommunicationInfo } from '../../../../../services/freelancer';
import CustomAlert from '../../../../../components/common/CAlert';
import { Theme } from '../../../../../Theme';
import { useNavigate } from 'react-router-dom';
import SuccessModal from '../../../../../components/common/SuccessModal';
import { getAllTermsCondition } from '../../../../../services/lov';

interface PaymentBillingInformationProps {
    moveTo: (e: any) => void;
    state: any;
    isVendorDetailNotEmpty: boolean;
    selectedTab: number;
    maxVisitedTab: number;
}


const preferedCommunication = [
    { value: 'Email', label: 'Email' },
    { value: 'PhoneNumber', label: 'Phone Number' },
    { value: 'WhatsApp', label: 'Whats App' },
];
const TermsAndConditions: React.FC<PaymentBillingInformationProps> = ({ moveTo, state, isVendorDetailNotEmpty, selectedTab, maxVisitedTab }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });
    const [isSuccess, setIsSuccess] = useState(false);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (isVendorDetailNotEmpty) {
            console.log('data present');
            form.setFieldsValue(state);
        } else {
            console.log('data not present');
        }
    }, [])

    
    const { data, error: countryError, isLoading: isCountryLoading } = useQuery('termsCondition', getAllTermsCondition);


    const { mutate, isLoading: addLoading } = useMutation(SaveSubmmitedInfo, {
        onSuccess: (response) => {
            if (response.succeeded) {
                setIsSuccess(true);
            } else {
                setAlert({ message: response.message, type: 'error', visible: true });
            }
        },
        onError: (error: any) => {
            setAlert({ message: error?.message || 'An error occurred. Please try again.', type: 'error', visible: true });
        },
    });

    const handleSave = (values: any) => {
        if(checked){
            const obj = {
                isEnableTermAndCondition: checked,
                tabNumber: selectedTab < maxVisitedTab ? maxVisitedTab : selectedTab,
                id:  state?.id 
            }
            mutate(obj);
        }else{
            setAlert({ message: 'Please enable the terms and conditions.', type: 'error', visible: true });
        }
    }

    const onChange: CheckboxProps['onChange'] = (e) => {
        console.log(`checked = ${e.target.checked}`);
        setChecked(e.target.checked);
      };

    return (
        <>
            <div className='text-center'>
                <img
                    src={Theme.icons.icn_terms_condition}
                    alt='terms & conditions'
                    className="category-icon mb-0"
                    style={{ width: '72px', height: '72px' }}
                />
                <p className='user-name mt-1'>{t("Terms & Conditions")}</p>
            </div>


            <Form
                form={form}
                layout="vertical"
                onFinish={handleSave}
                initialValues={{ remember: true }}
            >
                <div className='row'>
                    <div className='view-container mt-3' dangerouslySetInnerHTML={{ __html: data?.data[0]?.description }} />
                    <Form.Item
                            name="isEnableTermAndCondition"
                            rules={[{ required: false, message: t("Please enable the terms and conditions") }]}
                        >
                           <Checkbox onChange={onChange}>{t("Terms & Conditions")}</Checkbox>
                        </Form.Item>
                    <Row gutter={8} style={{ marginTop: '15px' }}>
                        <Col span={4}>
                            <CButton loading={addLoading} style={{ width: '100%' }} htmlType="submit">
                                Submit
                            </CButton>
                        </Col>
                    </Row>
                </div>

            </Form >

            <SuccessModal
                    visible={isSuccess}
                    message={'You have successfully onboard vendor.'}
                    onOk={() =>   navigate('/jive/vendorList')}
                />

            <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />

        </>
    );
};

export default TermsAndConditions;
