import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import CInput from '../../../../../components/common/CInput';
import CButton from '../../../../../components/common/CButton';
import Phone from '../../../../../components/common/CPhone';
import { useMutation, useQuery } from 'react-query';
import { getCountries } from '../../../../../services/manageUser';
import { Theme } from '../../../../../Theme';
import CurrentLocationMap from '../../../../../components/ui/CurrentLocationMap';
import CModal from '../../../../../components/common/CModal';
import { AddUpdateVendorFreelancer } from '../../../../../services/freelancer';
import CustomAlert from '../../../../../components/common/CAlert';
import { useNavigate } from 'react-router-dom';

interface VendorInformationProps {
    moveTo: (e: any) => void;
    state: any;
    isVendorDetailNotEmpty: boolean;
    selectedTab:number;
    maxVisitedTab:number;
}

const VendorInformation: React.FC<VendorInformationProps> = ({ moveTo, state, isVendorDetailNotEmpty, selectedTab, maxVisitedTab  }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [address, setAddress] = useState('');
    const [showMap, setShowMap] = useState(false);
    const [exit, setExit] = useState(false);
    const [phoneDetails, setPhoneDetails] = useState<{ phoneNumber: string; countryId: number; prefix: string }>({ phoneNumber: '', countryId: 0, prefix: '' });
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });
    const getLocation = () => {
        setShowMap(true);
    };


    const handleOk = () => {
        setShowMap(false);
    };

    const handleCancel = () => {
        setShowMap(false);
    };


    const handleLocationSelect = (location: string) => {
        setAddress(location);
    };


    const handlePhoneNumberChange = (details: { phoneNumber: string; countryId: number; prefix: string }) => {
        setPhoneDetails(details);
        if (details?.phoneNumber !== '') {
            form.setFieldsValue({
                phoneNumber: details?.prefix + details?.phoneNumber,
            });
        }else {
            form.setFieldsValue({
                phoneNumber: '',
            });
            
            // Revalidate the field to trigger the required rule
            // form.validateFields(['phoneNumber']);
        }
    };

    useEffect(() => {
        if (address !== '') {
            form.setFieldsValue({
                businessAddress: address,
            });
        }
    }, [address])

    useEffect(() => {
        if (isVendorDetailNotEmpty) {
            console.log('data present');
            form.setFieldsValue(state);
            form.setFieldsValue({
                phoneNumber: state?.phoneNumber,
            });
        } else {
            console.log('data not present');
        }
    }, [])

    const { data: countryData, error: countryError, isLoading: isCountryLoading } = useQuery('countries', getCountries);


    const { mutate, isLoading: addLoading } = useMutation(AddUpdateVendorFreelancer, {
        onSuccess: (response) => {
            if (response.succeeded) {
                if (exit) {
                    navigate('/jive/vendorList');
                } else {
                    moveTo(response.data);
                }
            } else {
                setAlert({ message: response.message, type: 'error', visible: true });
            }
        },
        onError: (error: any) => {
            setAlert({ message: error?.message || 'An error occurred. Please try again.', type: 'error', visible: true });
        },
    });

    const handleSave = (values: any) => {
        const obj = {
            ...values,
            id: isVendorDetailNotEmpty ? state?.id : null,
            CountryId: phoneDetails?.countryId,
            ShortPhoneNumber: phoneDetails?.phoneNumber !== '' ? phoneDetails?.phoneNumber : state?.shortPhoneNumber,
            password: "123123",
            confirmPassword: "123123",
            userType: "Vendor",
            tabNumber: selectedTab < maxVisitedTab ? maxVisitedTab : selectedTab
        }
        mutate(obj);
    }

    const handleSaveAndExit = () => {
        setExit(true);
        form.submit();
    };

    return (
        <>

            <CModal
                title="Select Your Location"
                open={showMap}
                onOk={handleOk}
                onCancel={handleCancel}
                width={800}
                mask={false}
            >
                 <div style={{ height: '400px', position: 'relative', zIndex: 1050 }}>
        <CurrentLocationMap onLocationSelect={handleLocationSelect} />
    </div>
    <div>
        <strong>Selected Location:</strong> {address}
    </div>
            </CModal>
            <p className='user-name'>{t("Vendor Information")}</p>

            <Form
                form={form}
                layout="vertical"
                onFinish={handleSave}
                initialValues={{ remember: true }}
            >



                <div className='row'>
                    <div className='col-lg-5'>
                        <Form.Item
                            name="businessName"
                            rules={[{ required: true, message: t("Please enter the business name") }]}
                        >
                            <CInput label={t("Business Name")} placeholder={t("Enter Business Name")} />
                        </Form.Item>
                    </div>
                    <div className='col-lg-5'>
                        <Form.Item
                            name="contactPersonName"
                            rules={[{ required: true, message: t("Please enter the contact person name") }]}
                        >
                            <CInput label={t("Contact Person Name")} placeholder={t("Enter Contact Person Name")} />
                        </Form.Item>
                    </div>

                    <div className='col-lg-5'>

                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter a valid email",
                                    validator: (_, value) => {
                                        if (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject("Please input your E-mail!");
                                        }
                                    },
                                },
                            ]}
                        >
                            <CInput disabled={isVendorDetailNotEmpty ? true : false} label={t("email_address")} placeholder={t("email_placeholder")} />
                        </Form.Item>
                    </div>
                    <div className='col-lg-5'>
                        <Form.Item
                            name="websiteURL"
                            rules={[{ required: true, message: 'Please enter the web url' }]}
                        >
                            <CInput label={t("Website Link")} placeholder={t("Enter Website Link")} />
                        </Form.Item>
                    </div>
                    <div className='col-lg-5'>
                        <Form.Item
                            name="phoneNumber"
                            rules={[{ required: true, message: 'Please enter the phone number' }]}
                        >
                            {countryData && <Phone
                                label='Phone Number'
                                countries={countryData?.data}
                                onPhoneNumberChange={handlePhoneNumberChange}
                                initialValue={state?.phoneNumber}
                                isDisabled={isVendorDetailNotEmpty ? true : false}
                            />}

                        </Form.Item>
                    </div>

                    <div className='col-lg-5 d-flex align-items-center justify-content-between'>
                        <div style={{ width: '85%' }}>
                            <Form.Item
                                name="businessAddress"
                                rules={[{ required: true, message: 'Please enter the business address' }]}
                            >
                                <CInput
                                    // className='w-100'
                                    label={t("Business Address")}
                                    placeholder={t("Enter Business Address")}
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </Form.Item>
                        </div>


                        <div className='inputfield d-flex  align-items-center p-2 ' style={{ marginTop: '16px' }} onClick={getLocation}>
                            <img src={Theme.icons.icn_gps_location} alt="Get Location" />
                        </div>
                    </div>


                    <Row gutter={8} style={{ marginTop: '15px' }}>
                        <Col span={4}>
                            <CButton className='cancelBtn' loading={addLoading && exit} onClick={handleSaveAndExit}>
                                {t("Save & Exit")}
                            </CButton>
                        </Col>
                        <Col span={4}>
                            <CButton loading={addLoading && !exit} style={{ width: '100%' }} htmlType="submit">
                                {t("Next")}
                            </CButton>
                        </Col>
                    </Row>
                </div>

            </Form >

            <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />

        </>
    );
};

export default VendorInformation;
