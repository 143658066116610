import React, { useState } from 'react';
import { Form, Input, Row, Col, Switch } from 'antd';
import { Theme } from '../../../Theme';
import CustomAlert from '../../../components/common/CAlert';
import SuccessModal from '../../../components/common/SuccessModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { changeUserStatus } from '../../../services/manageUser';
import { useMutation } from 'react-query';
import AlertModal from '../../../components/common/AlertModal';
import { useTranslation } from 'react-i18next';
import { changeCustomerStatus } from '../../../services/customer';
import dayjs from 'dayjs';
import { getTheFirstTwoLetterOfString } from '../../../utils/functions';

const ViewUser: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const { record, module } = location.state;
    const [isSuccess, setIsSuccess] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [switchChecked, setSwitchChecked] = useState(record?.active);
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });

    const { mutate, isLoading, isError, error } = useMutation(module === 'customer' ? changeCustomerStatus : changeUserStatus, {
        onSuccess: (response) => {
            if (response.succeeded) {
                setAlert({ message: 'Status Updated successfully!', type: 'success', visible: true });
            } else {
                setAlert({ message: response.message, type: 'error', visible: true });
            }
        },
        onError: (error: any) => {
            setAlert({ message: error ? error?.message : 'An error occurred. Please try again.', type: 'error', visible: true });
        },
    });

    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error: {error?.message}</div>;

    const handleSwitchChange = () => {
        setIsModalVisible(true);
    };

    const handleModalOk = () => {
        const newStatus = !switchChecked;
        const obj = {
            id: record?.id,
            status: newStatus,
        };
        mutate(obj);
        setSwitchChecked(newStatus);
        setIsModalVisible(false);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div style={{ padding: '20px', color: Theme.colors.black }}>
            <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                    <img src={Theme.icons.icn_arrow_back} className='cursor-pointer' alt="icn_user_add" style={{ height: '20px' }} onClick={() => navigate(-1)} />
                    <p className='list-heading m-0 mx-2'>{t("manage_users")}</p>
                </div>
                <div className='d-flex align-items-center' style={{ marginRight: '5px' }}>
                    <p className='m-0 admin' style={{ color: Theme.colors.black }}>{switchChecked ? 'Active' : 'Inactive'}</p>
                    <Switch checked={switchChecked} onChange={handleSwitchChange} className='mx-2' />
                </div>
            </div>
            <div className='mt-3' style={{ backgroundColor: Theme.colors.white, padding: '24px', borderRadius: '16px' }}>
                <p className='user-name'>{t("user_details")}</p>
                <div style={{ backgroundColor: '#F3F3F3', padding: '24px', borderRadius: '16px' }}>
                    <div className='d-flex align-items-center'>
                        <div className='bold-name-circle'>
                            <p className='m-0'>{getTheFirstTwoLetterOfString(record?.name)}</p>
                        </div>
                        <p className='user-name m-0 mx-2'>{record?.name}</p>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-lg-3'>
                            <p className='detail-label m-0'>{module === 'customer' ? t("date_of_joining") : t("user_role")}</p>
                            <p className='detail-value'>{module === 'customer' ? dayjs(record?.joiningDate)?.format('DD.MM.YYYY') : record?.roleName}</p>
                        </div>
                        <div className='col-lg-3'>
                            <p className='detail-label m-0'>{t("phone_number")}</p>
                            <p className='detail-value'>{record?.phoneNumber}</p>
                        </div>
                        <div className='col-lg-3'>
                            <p className='detail-label m-0'>{t("email_address")}</p>
                            <p className='detail-value'>{record?.email}</p>
                        </div>
                    </div>
                </div>
            </div>
            <AlertModal
                visible={isModalVisible}
                message={`Are you sure you want to ${switchChecked ? 'deactivate' : 'activate'} this user?`}
                onDelete={handleModalOk}
                onCancel={handleModalCancel}
            />
            <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />
        </div>
    );
};

export default ViewUser;
