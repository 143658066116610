import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import CModal from './CModal';
import CButton from './CButton';
import { Theme } from '../../Theme';

interface ConfirmActivationModalProps {
  visible: boolean;
  message?: string;
  onCancel: () => void;
  onDelete: () => void;
}

const AlertModal: React.FC<ConfirmActivationModalProps> = ({
  visible,
  message,
  onCancel,
  onDelete,
}) => {
  return (
    <CModal
      visible={visible}
      onCancel={onCancel}
      footer={null} // To use custom footer buttons
      centered
      title={null}
      className='success-modal'
    >
      <div style={{ textAlign: 'center' }}>
        <img src={Theme.icons.icn_alert} style={{ fontSize: '20px', color: '#f5222d' }} />
        <p className='user-name m-0'>Alert</p>
        <p className='login-des'>{message}</p>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    
                        <CButton className='cancelBtn' onClick={onCancel}>
                            Cancel
                        </CButton>
                 
                        <CButton style={{ width: '100%' }} onClick={onDelete}>
                            Save
                        </CButton>
        </div>
      </div>
    </CModal>
  );
};

export default AlertModal;
