import instance from './api_instance'

export const get = async (url: any, credentials: object) => {
  const response = await instance.get(url, {
    params: credentials
  })
  return response.data
}

export const post = async (url: any, credentials: object) => {
  const response = await instance.post(url, credentials)
  return response.data
}