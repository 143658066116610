import { t } from 'i18next'
import CButton from '../../../../components/common/CButton'
import { Theme } from '../../../../Theme'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import search from 'antd/es/transfer/search'
import { useQuery } from 'react-query'
import { getAllTermsCondition } from '../../../../services/lov'
import { useEffect, useState } from 'react'

const TermsAndConditions: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [termAndConditions, setTermAndConditions] = useState<{
    [key: string]: any
  }>({})

  const redirectUrl = `/jive/terms-and-conditions`

  const [alert, setAlert] = useState<{
    message: string
    type: 'success' | 'error'
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })

  const { data, error, isLoading } = useQuery(
    ['TermAndCondition', search],
    () =>
      getAllTermsCondition({
        Search: search
      }),
    {
      keepPreviousData: true,
      onError: (err: any) => {
        setAlert({
          message: err?.message || t('An error occurred'),
          type: 'error',
          visible: true
        })
      }
    }
  )

  useEffect(() => {
    if (data && data.data && data.data.length > 0) {
      setTermAndConditions(data.data[0])
    }
  }, [data])

  return (
    <>
      <div
        style={{
          backgroundColor: Theme.colors.white,
          padding: '24px',
          borderRadius: '16px'
        }}
      >
        <div className='d-flex justify-content-between align-items-center'>
          <p className='m-0 list-heading'>{t('Terms & Conditions')}</p>
          <div className='d-flex justify-content-between'>
            {!termAndConditions.id && (
              <CButton
                className='addUserBtn'
                onClick={() => navigate(`${redirectUrl}/add/new`)}
              >
                <img
                  src={Theme.icons.icn_add_btn}
                  alt='icn_add_btn'
                  style={{ height: '20px' }}
                />
                <p className='m-0'>{t('Add Terms & Conditions')}</p>
              </CButton>
            )}
            {termAndConditions.id && (
              <button
                className='btn-unfilled'
                onClick={() =>
                  navigate(
                    `/jive/term-and-conditions/edit/${termAndConditions.id}`,{state : termAndConditions}
                  )
                }
              >
                <img
                  src={Theme.icons.icn_edit}
                  alt='icn_user_add'
                  style={{ height: '15px' }}
                />
                Edit
              </button>
            )}
          </div>
        </div>

        <div className='view-container mt-3' dangerouslySetInnerHTML={{ __html: termAndConditions?.description }} />
        
      </div>
    </>
  )
}
export default TermsAndConditions
