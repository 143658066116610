import React, { useState } from 'react';
import { Layout, Tag } from 'antd';
import { Theme } from '../../Theme';
import Header from '../../components/common/Header';
import MenuWrapper from '../../components/ui/MenuWrapper';
import { Outlet, useNavigate } from 'react-router-dom';

const { Sider, Content } = Layout;

const getImage = (imgPath: string, selectedImgPath?: string, isSelected?: boolean) => (
  <img
    src={isSelected && selectedImgPath ? selectedImgPath : imgPath}
    style={{ height: '20px' }}
  />
);




const PortalScreen: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState('internalUser');
  const [highlightStyle, setHighlightStyle] = useState({ top: 0, height: 0 });
  const [openKeys, setOpenKeys] = useState<string[]>([]); // Added to track open submenus
  const navigate = useNavigate();

  const items2 = [
    {
      key: 'home',
      icon: getImage(Theme.icons.icn_home),
      label: 'Home',
      path: '/home', // Add path for navigation
    },
    {
      key: 'manageUsers',
      icon: getImage(Theme.icons.icn_manage_user),
      label: 'Manage Users',
      children: [
        {
          key: 'internalUser',
          label: 'Manage Internal User',
          path: '/manageInternalUser', // Add path for each child menu item
        },
        {
          key: 'customer',
          label: 'Manage Customer',
          path: '/manageCustomer',
        },
        {
          key: 'vendor',
          label: 'Manage Vendor',
          path: '/manageVendor',
        },
        {
          key: 'freelancer',
          label: 'Manage Freelancer',
          path: '/manageFreelancer',
        },
      ],
    },
    {
      key: 'manageBooking',
      icon: getImage(Theme.icons.icn_manage_booking),
      label: 'Manage Booking',
      children: [
        {
          key: 'serviceEvent',
          label: 'Service Booking',
          path: '/serviceBooking',
        },
        {
          key: 'customEvent',
          label: 'Custom Event Booking',
          path: '/customEvent',
        },
      ]
    },
    {
      key: 'servicesPackages',
      icon: getImage(Theme.icons.icn_services_packages, Theme.icons.icn_white_services_packages, selectedKey === 'servicesPackages'),
      label: 'Services & Packages',
      path: '/servicesAndPackagesList',
    },
    {
      key: 'paymentAndPromotion',
      icon: getImage(Theme.icons.icn_payment_logs),
      label: 'Payment & Promotion',
      children: [
        {
          key: 'paymentLogs',
          label: 'Payment Logs',
          path: '/paymentLogs',
        },
        {
          key: 'promoCode',
          label: 'Promo Code',
          path: '/promoCodesList',
        },
      ]
    },
   
    {
      key: 'onboarding',
      icon: getImage(Theme.icons.icn_onboarding),
      label: 'Onboarding',
      children: [
        {
          key: 'vendorOnboarding',
          label: 'Vendor Onboarding',
          path: '/vendorList',
        },
        {
          key: 'freelancerOnboarding',
          label: 'Freelancer Onboarding',
          path: '',
        },
      ],
    },
    
    {
      key: 'reports',
      icon: getImage(Theme.icons.icn_reports, Theme.icons.icn_white_reports, selectedKey === 'reports'),
      label: 'Reports',
      path: '/reports',
    },
    {
      key: 'settings',
      icon: getImage(Theme.icons.icn_settings),
      label: 'Settings',
      children: [
        {
          key: 'manageProfile',
          label: 'Manage Profile',
          path: '/profile',
        },
        {
          key: 'roleAndPrivileges',
          label: 'Roles & Privileges',
          path: '',
        },
        {
          key: 'manageListOfValues',
          label: 'Manage List of values',
          path: '/EventPolicyManagementList',
        },
      ],
    },
  ];
  const handleMenuClick = (e: any) => {
    setSelectedKey(e.key);
    localStorage.removeItem('active');

    // Find the menu item that was clicked
    const menuItem = items2
      .flatMap((item) => (item.children ? item.children : [item]))
      .find((item) => item.key === e.key);

    // Navigate to the path of the clicked menu item
    if (menuItem?.path) {
      navigate(`/jive${menuItem.path}`);
    }

    // Close all open submenus if a non-submenu item is clicked
    const isSubmenuItem = items2.some(item => item.children && item.children.some(child => child.key === e.key));
    if (!isSubmenuItem) {
      setOpenKeys([]);  // Reset open submenus
    }

    // Find the submenu item to highlight
    const submenu = items2.find((item: any) => item.key === 'manageUsers');
    if (submenu && 'children' in submenu && Array.isArray(submenu.children)) {
      const index = submenu.children.findIndex((item: any) => item.key === e.key);
      if (index !== -1) {
        const menuItemHeight = 45; // The height of each menu item (should match CSS height)
        const top = index * menuItemHeight + 8;
        setHighlightStyle({ top, height: menuItemHeight });
      }
    }
  };

  const onLogOut = () => {
    localStorage.removeItem('authToken');
    navigate('/');
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <Sider width={260} style={{ background: Theme.colors.white }}>
        <div className='mt-4' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img alt="logo" src={Theme.icons.icn_main_logo} />
        </div>
        <div className='d-flex flex-column justify-content-between' style={{ height: '90%' }}>
          <MenuWrapper
            items={items2}
            selectedKey={selectedKey}
            highlightStyle={highlightStyle}
            onClick={handleMenuClick}
            openKeys={openKeys} // Pass openKeys to MenuWrapper
            setOpenKeys={setOpenKeys} // Pass setter to MenuWrapper
          />
          <div className='d-flex align-items-center justify-content-center w-100 mb-3 cursor-pointer'>
            <Tag className='status-listing w-75' style={{ borderColor: '#F55666' }} onClick={onLogOut}>
              <img src={Theme.icons.icn_logout} style={{ height: '20px' }} />
              <p className='m-0 logout mx-2'>Logout</p>
            </Tag>
          </div>
        </div>
      </Sider>
      <Layout>
        <Header />
        <Content
          style={{
            padding: '24px',
            margin: 0,
            backgroundColor: Theme.colors.contentColor,
            overflowY: 'scroll',
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};


export default PortalScreen;
