import React from "react";
import CustomTabs from "../../../components/common/CTabs";
import CButton from "../../../components/common/CButton";
import CInput from "../../../components/common/CInput";
import { Theme } from "../../../Theme";
import CTable from "../../../components/common/CTable";
import { ColumnsType } from 'antd/lib/table';
import { useQuery } from "react-query";
import { getAllManageUser } from "../../../services/manageUser";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tag } from "antd";
import _ from 'lodash';

const tabs = [
  { key: 'AllUser', label: 'All Users' },
  { key: 'BookingAdmin', label: 'Booking Admin' },
  { key: 'FinanceManager', label: 'Finance Manager' },
  { key: 'EventsManager', label: 'Events Manager' },
];

const ManageUserList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const activeKey = searchParams.get('RoleName') || 'AllUser';
  const pageNumber = Number(searchParams.get('PageNumber')) || 1;
  const pageSize = Number(searchParams.get('PageSize')) || 10;
  const search = searchParams.get('Search') || "";

  const handleTabChange = (key: string) => {
    setSearchParams({ RoleName: key, PageNumber: '1', PageSize: pageSize.toString() }, { replace: true });
  };

  const handleTableChange = (page: number, pageSize: number) => {
    setSearchParams({ RoleName: activeKey, PageNumber: page.toString(), PageSize: pageSize.toString() }, { replace: true });
  };

  const handleSearchChange = _.debounce((e: any) => {
    setSearchParams({ RoleName: activeKey, PageNumber: '1', PageSize: pageSize.toString(), Search: e.target.value }, { replace: true });
  }, 1000)



  const columns: ColumnsType = [
    {
      title: t("ID"),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t("user_name"),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t("email_address"),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t("phone_number"),
      dataIndex: 'phoneNumber',
      key: 'phone',
    },
    {
      title: t("role"),
      dataIndex: 'roleId',
      key: 'roleName',
      render: (text, record, index) => {
        return (
          <Tag
            className={`status-listing  ${record.roleName === 'Booking Admin' ? 'bookingAdmin' : record.roleName === 'Finance Manager' ? 'financeManager' : 'eventManager'}`}
          >
            {record?.roleName}
          </Tag>
        )
      }
    },
    {
      title: t('action'),
      render: (text, record, index) => (
        <div className="d-flex justify-content-between align-items-center w-25">
          <img src={Theme.icons.icn_view_list} alt="icn_user_add" style={{ height: '20px' }} onClick={() => navigate('/jive/viewUser', { state: { record } })} />
          <img src={Theme.icons.icn_edit_list} className="mx-2" alt="icn_user_edit" style={{ height: '20px' }} onClick={() => navigate('/jive/addNewUser', { state: { record } })} />

        </div>
      ),
      key: 'action',
    },
  ];


  const { data, error, isLoading } = useQuery(
    ['adminUsers', activeKey, pageNumber, pageSize, search],
    () => getAllManageUser({ RoleName: activeKey, PageNumber: pageNumber, PageSize: pageSize, Search: search })
  );

  return (
    <>
      <p className="page-heading">{t("manage_users")}</p>
      <CustomTabs tabs={tabs} activeKey={activeKey} onTabChange={handleTabChange} />

      <div className="d-flex justify-content-between align-items-center" >

        <p className="m-0 list-heading">{t("all_users_list")}</p>
        <div className="d-flex justify-content-between">
          <CInput
            prefix={
              <img alt="" src={Theme.icons.icn_search} style={{ height: "16px" }} />
            }
            className="searchInput"
            placeholder="Search"
            onChange={handleSearchChange}
          />
          <CButton className="addUserBtn" onClick={() => navigate('/jive/addNewUser')}>
            <img src={Theme.icons.icn_user_add} alt="icn_user_add" style={{ height: '20px' }} />
            <p className="m-0">{t("add_new_user")}</p>
          </CButton>
        </div>
      </div>
      <CTable
        footer="End of Table"
        columns={columns}
        dataSource={data?.data}
        pagination={{
          showSizeChanger: false,
          showQuickJumper: false,
          current: pageNumber,
          pageSize: pageSize,
          total: data?.totalRecords,
          onChange: handleTableChange,
          showTotal: (total: number, range: [number, number]) => (
            <p>{`Showing ${range[0]} - ${range[1]} of ${total} Results`}</p>
          ),
          itemRender: (page, type, originalElement) => {
            if (type === 'prev') {
              return <p className="detail-label">{`< Prev`}</p>;
            }
            if (type === 'next') {
              return <p className="detail-label">{`Next >`}</p>;
            }
            return originalElement;
          }
        }}
        loading={isLoading}
      />
    </>
  );
};

export default ManageUserList;
