import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Form, Input, Row, Col, Spin } from 'antd'
import { useMutation } from 'react-query'
import CustomAlert from '../../../../../components/common/CAlert'
import CButton from '../../../../../components/common/CButton'
import CInput from '../../../../../components/common/CInput'
import SuccessModal from '../../../../../components/common/SuccessModal'
import { addCategory, addEventType } from '../../../../../services/lov'
import { Theme } from '../../../../../Theme'
import ImageUploader from '../../../../../components/common/ImageUploader'

const AddSubCategories: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { type, id, ParentId } = useParams()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isSuccess, setIsSuccess] = useState(false)
  const [image, setImageFile] = useState<File | null>(null)

  const handleFileSelect = (file: File | null) => {
    setImageFile(file)
  }
  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })

  const getTitle = () => {
    if (type == 'view' && id != 'add') {
      return 'Category Details'
    }
    return type == 'edit' ? 'Category Details' : 'Sub Category Details'
  }

  const record = location.state?.record

  const { mutate, isLoading } = useMutation(addCategory, {
    onSuccess: response => {
      if (response.succeeded) {
        setIsSuccess(true)
      } else {
        setAlert({ message: response.message, type: 'error', visible: true })
      }
    },
    onError: (error: any) => {
      setAlert({
        message: error?.message || 'An error occurred. Please try again.',
        type: 'error',
        visible: true
      })
    }
  })

  useEffect(() => {
    if (location.state?.record) {
      form.setFieldsValue({
        Name: location.state.record.name
      })
    }
  }, [location.state])

  const handleSave = (values: any) => {
    const obj = {
      ...values,
      ParentId: ParentId
    }

    const viewObj = {
      Name: values.Name,
      ParentId: ParentId,
      ...(record && { id: record.id,imagePath: record.imagePath }),

    }
    console.log('values', viewObj)

    const formData = new FormData()
    // formData.append('ParentId','null')
    // for (const key in values) {
    //   formData.append(key, values[key])
    // }
    if (image) {
      formData.append('image', image)
    }
    formData.append('obj', JSON.stringify(record ? viewObj : obj))

    // formData.append('obj', JSON.stringify(obj));

    // if (record) {
    //   formData.append('id', record.id)
    // }
    // debugger
    mutate(formData)
  }
  return (
    <>
      <div style={{ padding: '20px', color: Theme.colors.black }}>
        <div className='d-flex align-items-center mb-4'>
          <img
            src={Theme.icons.icn_arrow_back}
            className='cursor-pointer'
            alt='icn_user_add'
            style={{ height: '20px' }}
            onClick={() => navigate(-1)}
          />
          <p className='list-heading m-0 mx-2'>{t('Categories')}</p>
        </div>
        {type == 'view' && id != 'add' && (
          <div
            style={{
              backgroundColor: Theme.colors.white,
              padding: '24px',
              borderRadius: '16px'
            }}
          >
            <p className='user-name'>{getTitle()}</p>
            <div className='view-container'>
              <div className='view-container-content'>
                <div>
                  <p className='label m-0'>Sub Category Name</p>
                  <p className='detail m-0'>{record?.name}</p>
                </div>
                <button
                  className='btn-unfilled'
                  onClick={() =>
                    navigate(
                      `/jive/categories/${ParentId}/subcategories/edit/${id}`,
                      {
                        state: { record: record }
                      }
                    )
                  }
                >
                  <img
                    src={Theme.icons.icn_edit}
                    alt='icn_user_add'
                    style={{ height: '15px' }}
                  />
                  Edit
                </button>
              </div>
              {record?.imagePath && (
                <div
                  className='image-preview mt-3'
                  style={{ pointerEvents: 'none', opacity: 0.8 }}
                >
                  <p className='label m-0 mb-2'>{t('Parent Category Image')}</p>

                  <ImageUploader
                    label='Drag & Drop or Upload'
                    preview={record?.imagePath}
                    onFileSelected={handleFileSelect}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {type != 'view' && (
          <div>
            <Form
              form={form}
              layout='vertical'
              onFinish={handleSave}
              style={{
                backgroundColor: Theme.colors.white,
                padding: '24px',
                borderRadius: '16px'
              }}
            >
              <p className='user-name'>{getTitle()}</p>

              <>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      name='Name'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter the sub category name'
                        }
                      ]}
                    >
                      <CInput
                        label='Sub Category Name'
                        placeholder='Enter Sub Category Name'
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item label='Sub Category Image'>
                  <ImageUploader
                    label='Drag & Drop or Upload'
                    preview={record?.imagePath}
                    onFileSelected={handleFileSelect}
                  />
                </Form.Item>

                <Row gutter={8} style={{ marginTop: '15px' }}>
                  <Col span={4}>
                    <CButton
                      loading={isLoading}
                      style={{ width: '100%' }}
                      htmlType='submit'
                    >
                      {record ? 'Save' : 'Add'}
                    </CButton>
                  </Col>
                </Row>
              </>
            </Form>
            <SuccessModal
              visible={isSuccess}
              message={
                record
                  ? 'Sub Category updated successfully'
                  : 'Sub Category added successfully'
              }
              onOk={() => navigate(`/jive/categories/view/${ParentId}`)}
            />
            <CustomAlert
              message={alert.message}
              type={alert.type as 'success' | 'info' | 'warning' | 'error'}
              visible={alert.visible}
              onClose={() => setAlert({ ...alert, visible: false })}
              duration={3000}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default AddSubCategories
