import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import CInput from '../../../../../components/common/CInput';
import CButton from '../../../../../components/common/CButton';
import { useMutation, useQuery } from 'react-query';
import { getCountries } from '../../../../../services/manageUser';
import { SavePaymentDetailInfo } from '../../../../../services/freelancer';
import CustomAlert from '../../../../../components/common/CAlert';
import CustomSelect from '../../../../../components/common/CSelect';
import { getCityById } from '../../../../../services/lov';
import { useNavigate } from 'react-router-dom';

interface PaymentBillingInformationProps {
    moveTo: (e: any) => void;
    state: any;
    isVendorDetailNotEmpty: boolean;
    selectedTab: number;
    maxVisitedTab: number;
}

const PaymentBillingInformation: React.FC<PaymentBillingInformationProps> = ({ moveTo, state, isVendorDetailNotEmpty, selectedTab, maxVisitedTab }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [selectedCountry, seSelectedCountry] = useState();
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });
    const navigate = useNavigate();
    const [exit, setExit] = useState(false);
    

    useEffect(() => {
        if (isVendorDetailNotEmpty) {
            console.log('data present');
            form.setFieldsValue(state);
            seSelectedCountry(state.countryId);
        } else {
            console.log('data not present');
        }
    }, [])

    const { data, error: countryError, isLoading: isCountryLoading } = useQuery('countries', getCountries);

    const { data: cityData, error: cityError, isLoading: isCityLoading } = useQuery(['cityById', selectedCountry],() =>  getCityById({CountryId : selectedCountry}));


    const getData = (val:any) => {
        if (!val) return []; // Return an empty array if data is undefined
        return val.data.map((x: any) => ({
            value: x.id,
            label: x.name ? x.name : x.cityName
        }));
    };

    const { mutate, isLoading } = useMutation(SavePaymentDetailInfo, {
        onSuccess: (response) => {
            if (response.succeeded) {
                if(exit){
                    navigate('/jive/vendorList');
                }else{
                    moveTo(response.data);
                }
            } else {
                setAlert({ message: response.message, type: 'error', visible: true });
            }
        },
        onError: (error: any) => {
            setAlert({ message: error?.message || 'An error occurred. Please try again.', type: 'error', visible: true });
        },
    });

    const handleSave = (values: any) => {
        const cityName = cityData?.data?.filter((x:any) => x.id === values.cityId);
        const countryName = data?.data?.filter((x:any) => x.id === values.countryId);
        const obj = {
            ...values,
            id:state?.id ,
            cityName: cityName[0]?.cityName,
            countryName: countryName[0]?.name,
            tabNumber: selectedTab < maxVisitedTab ? maxVisitedTab : selectedTab,
        }
        mutate(obj);
    }

    const handleSaveAndExit = () => {
        setExit(true);
        form.submit();
    };

    return (
        <>


            <p className='user-name'>{t("Payment & Billing Information")}</p>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSave}
                initialValues={{ remember: true }}
            >



                <div className='row'>
                    <div className='col-lg-5'>
                        <Form.Item
                            name="bankName"
                            rules={[{ required: true, message: t("Please enter the bank name") }]}
                        >
                            <CInput label={t("Bank Name")} placeholder={t("Enter Bank Name")} />
                        </Form.Item>
                    </div>
                    <div className='col-lg-5'>
                        <Form.Item
                            name="accountHolderName"
                            rules={[{ required: true, message: t("Please enter the account holder name") }]}
                        >
                            <CInput label={t("Account Holder Name")} placeholder={t("Enter Account Holder Name")} />
                        </Form.Item>
                    </div>

                    <div className='col-lg-5'>

                        <Form.Item
                            name="accountNumber"
                            rules={[{ required: true, message: t("Please enter the account number") }]}
                        >
                            <CInput label={t("Account Number")} placeholder={t("Enter Account Number")} />
                        </Form.Item>
                    </div>
                    <div className='col-lg-5'>
                        <Form.Item
                            name="countryId"
                            rules={[{ required: true, message: t("Please choose a country") }]}
                        >
                            <CustomSelect
                                label='Select Country'
                                options={getData(data)}
                                placeholder={t("Choose Country")}
                            />
                        </Form.Item>
                    </div>
                    <div className='col-lg-5'>
                        <Form.Item
                            name="cityId"
                            rules={[{ required: true, message: t("Please choose a city") }]}
                        >
                            <CustomSelect
                                label='Select City'
                                options={getData(cityData)}
                                // value={selectedRole}
                                placeholder={t("Choose City")}
                            />
                        </Form.Item>
                    </div>


                    <Row gutter={8} style={{ marginTop: '15px' }}>
                        <Col span={4}>
                            <CButton className='cancelBtn' loading={isLoading && exit} onClick={handleSaveAndExit}>
                            {t("Save & Exit")}
                            </CButton>
                        </Col>
                        <Col span={4}>
                            <CButton loading={isLoading && !exit} style={{ width: '100%' }} htmlType="submit">
                            {t("Next")}
                            </CButton>
                        </Col>
                    </Row>
                </div>

            </Form >

            <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />

        </>
    );
};

export default PaymentBillingInformation;
