import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Spin } from 'antd'
import CInput from '../../../../components/common/CInput'
import { Theme } from '../../../../Theme'
import CButton from '../../../../components/common/CButton'
import { useMutation } from 'react-query'
import CustomAlert from '../../../../components/common/CAlert'
import SuccessModal from '../../../../components/common/SuccessModal'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { addCountry, getCountryById } from '../../../../services/lov'
import CitiesList from '../Cities/CitiesList'

const AddUpdateCountry: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { id, type } = useParams() // Added params to detect view/edit
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isSuccess, setIsSuccess] = useState(false)
  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })
  const [countryDetails, setCountryDetails] = useState<{
    [key: string]: any
  }>({})
  const [citiesCount, setCitiesCount] = useState(0)

  const handleCitiesCount = (count: number) => {
    setCitiesCount(count)
  }
  const record = location.state?.record

  const getTitle = () => {
    if (type === 'view' && id !== 'add') {
      return 'Country Details'
    }
    return type === 'edit' ? 'Edit Country' : 'Add Country'
  }

  // Fetch country data for viewing/editing
  const { mutate: fetchCountry, isLoading: isFetchingCountry } = useMutation(
    (id: any) => getCountryById(id),
    {
      onSuccess: response => {
        if (response.succeeded) {
          setCountryDetails(response.data) // Set country details for view mode
          form.setFieldsValue({
            countryName: response.data.name // Pre-fill form with country data
          })
        } else {
          setAlert({ message: response.message, type: 'error', visible: true })
        }
      },
      onError: (error: any) => {
        setAlert({
          message: error?.message || 'An error occurred. Please try again.',
          type: 'error',
          visible: true
        })
      }
    }
  )

  // If editing/viewing, fetch the country details
  useEffect(() => {
    if (type !== 'add' && id !== 'new') {
      fetchCountry(id)
    }
  }, [type, id, fetchCountry])

  const { mutate, isLoading } = useMutation(addCountry, {
    onSuccess: response => {
      if (response.succeeded) {
        setIsSuccess(true)
      } else {
        setAlert({ message: response.message, type: 'error', visible: true })
      }
    },
    onError: (error: any) => {
      setAlert({
        message: error?.message || 'An error occurred. Please try again.',
        type: 'error',
        visible: true
      })
    }
  })

  const handleSave = (values: any) => {
    const formData = new FormData()

    const obj = {
      ...values
    }

    const viewObj = {
        name: values.Name,
      ...(countryDetails && {
        id: countryDetails.id
      })
    }
    formData.append('obj', JSON.stringify(countryDetails ? viewObj : obj))

    mutate({obj : countryDetails ? viewObj : obj})
  }

  return (
    <>
      <div style={{ padding: '20px', color: Theme.colors.black }}>
        <div className='d-flex align-items-center mb-4'>
          <img
            src={Theme.icons.icn_arrow_back}
            className='cursor-pointer'
            alt='icn_user_add'
            style={{ height: '20px' }}
            onClick={() => navigate(-1)}
          />
          <p className='list-heading m-0 mx-2'>{getTitle()}</p>
        </div>

        {/* View Section */}
        {type == 'view' && id != 'add' && (
          <div
            style={{
              backgroundColor: Theme.colors.white,
              padding: '24px',
              borderRadius: '16px'
            }}
          >
            <p className='user-name'>{getTitle()}</p>
            <div className='view-container'>
              <div className='view-container-content' style={{width:'50%'}}>
                <div>
                  <p className='label m-0'>Country Name</p>
                  <p className='detail m-0'>{countryDetails?.name}</p>
                </div>
                <div>
                  <p className='label m-0'>No. of Cities</p>
                  <p className='detail m-0'>{citiesCount}</p>
                </div>
                {/* <button
                  className='btn-unfilled'
                  onClick={() =>
                    navigate(`/jive/country/edit/${id}`, {
                      state: { record: record }
                    })
                  }
                >
                  <img
                    src={Theme.icons.icn_edit}
                    alt='icn_user_add'
                    style={{ height: '15px' }}
                  />
                  Edit
                </button> */}
              </div>
            </div>
          </div>
        )}

        {/* Edit/Add Form Section */}
        {type !== 'view' && (
          <Form
            form={form}
            layout='vertical'
            onFinish={handleSave}
            style={{
              backgroundColor: Theme.colors.white,
              padding: '24px',
              borderRadius: '16px'
            }}
          >
            <p className='user-name'>{getTitle()}</p>

            {isFetchingCountry ? (
              <div
                className='d-flex align-items-center justify-content-center'
                style={{ height: '200px' }}
              >
                <Spin />
              </div>
            ) : (
              <>
                <Row gutter={16}>
                  <Col span={8}>
                
                      <CInput
                        label='Country Name'
                        placeholder='Enter Country Name'
                        value='Saudia Arabia'
                        disabled={true}
                      />
                 
                  </Col>
                </Row>
                <Row gutter={8} style={{ marginTop: '15px' }}>
                  <Col span={4}>
                    <CButton
                      loading={isLoading}
                      style={{ width: '100%' }}
                      htmlType='submit'
                      disabled={true}
                    >
                      Save
                    </CButton>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        )}

        {/* Success and Alert Messages */}
        <SuccessModal
          visible={isSuccess}
          message={
            record
              ? 'Country updated successfully'
              : 'You have added a new country successfully.'
          }
          onOk={() => navigate('/jive/countryManagementList')}
        />
        <CustomAlert
          message={alert.message}
          type={alert.type as 'success' | 'info' | 'warning' | 'error'}
          visible={alert.visible}
          onClose={() => setAlert({ ...alert, visible: false })}
          duration={3000}
        />
      </div>
      {id != 'new' && (
        <div style={{ padding: '20px', color: Theme.colors.black }}>
          <CitiesList
            countryId={id}
            heading='List of Cities'
            onCountUpdate={handleCitiesCount}
          />
        </div>
      )}
    </>
  )
}

export default AddUpdateCountry
