import React, { useEffect, useState } from 'react'
import CustomTabs from '../../../components/common/CTabs'
import { useTranslation } from 'react-i18next'
import CustomRequests from './CustomRequests'
import VendorBookings from './VendorBookings'

const tabs = [
  { key: 'CustomerRequest', label: 'Customer Request' },
  { key: 'VendorBooking', label: 'Vendor Booking' },
  { key: 'Approved', label: 'Approved' },
  { key: 'Booked', label: 'Booked' },
  { key: 'Cancelled', label: 'Cancelled' }
]

const CustomEventList: React.FC = () => {
  const [activeKey, setActiveKey] = useState('CustomerRequest')
  const { t } = useTranslation()

  const handleTabChange = (key: string) => {
    setActiveKey(key)
    localStorage.setItem('active', key)
  }

  useEffect(() => {
    const storedActiveTab = localStorage.getItem('active')
    if (storedActiveTab) {
      setActiveKey(storedActiveTab)
    }
  }, [])

  const componentMapping: { [key: string]: React.FC<{ status: string }> } = {
    CustomerRequest: CustomRequests,
    VendorBooking: VendorBookings,
    Approved: VendorBookings,
    Booked: VendorBookings,
    Cancelled: VendorBookings
  }

  const Component = componentMapping[activeKey]

  return (
    <>
      <p className='page-heading'>{t('Custom Event Booking')}</p>
      <CustomTabs
        tabs={tabs}
        activeKey={activeKey}
        onTabChange={handleTabChange}
      />
      {Component && <Component status={activeKey} />}
    </>
  )
}

export default CustomEventList
