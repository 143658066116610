import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Theme } from '../../Theme'
import CButton from '../../components/common/CButton'
import AlertModal from '../../components/common/AlertModal'
import CustomAlert from '../../components/common/CAlert'
import { getPromoCodeById, updateStatusPromoCode } from '../../services/service'
import { Switch } from 'antd'
import dayjs from 'dayjs'
const PromoCodeView: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { t } = useTranslation()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [switchChecked, setSwitchChecked] = useState(false)

  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })
  const [promoCodeDetails, setPromoCodeDetails] = useState<{
    [key: string]: any
  }>({})

  const { data, isLoading, error, refetch } = useQuery(
    ['PromoCodeById', params?.id],
    () => getPromoCodeById({ Id: params?.id })
  )

  useEffect(() => {
    if (data) {
      setSwitchChecked(data?.data?.isActive)
      setPromoCodeDetails(data?.data)
    }
  }, [data])

  const { mutate, isLoading: submitLoading } = useMutation(
    updateStatusPromoCode,
    {
      onSuccess: response => {
        if (response.succeeded) {
          setIsModalVisible(false)
          refetch()
          setAlert({
            message: response.message,
            type: 'success',
            visible: true
          })
        } else {
          setAlert({ message: response.message, type: 'error', visible: true })
        }
      },
      onError: (error: any) => {
        setAlert({
          message: error?.message || 'An error occurred. Please try again.',
          type: 'error',
          visible: true
        })
      }
    }
  )
  const handleModalOk = () => {
    const newStatus = !switchChecked
    const obj = {
      id: promoCodeDetails?.id,
      active: newStatus
    }
    mutate(obj)
    setSwitchChecked(newStatus)
    setIsModalVisible(false)
  }
  const callRequest = () => {
    setIsModalVisible(true)
  }
  const handleModalCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <div style={{ padding: '20px', color: Theme.colors.black }}>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <img
              src={Theme.icons.icn_arrow_back}
              alt='icn_user_add'
              className='cursor-pointer'
              style={{ height: '20px' }}
              onClick={() => navigate(`/jive/promoCodesList`)}
            />
            <p className='list-heading m-0 mx-2'>{'Promo Code'}</p>
          </div>
          <div
            className='d-flex align-items-center'
            style={{ marginRight: '5px' }}
          >
            <p className='m-0 admin' style={{ color: Theme.colors.black }}>
              {switchChecked ? 'Active' : 'In Active'}
            </p>
            <Switch
              checked={switchChecked}
              onChange={callRequest}
              className='mx-2'
            />
          </div>
        </div>

        <div
          className='mt-3'
          style={{
            backgroundColor: Theme.colors.white,
            padding: '24px',
            borderRadius: '16px'
          }}
        >
          <div className='d-flex align-items-center justify-content-between mb-3'>
            <p className='user-name m-0'>{t('Promo Code Details')}</p>
            {promoCodeDetails?.allowUpdation && (
            <CButton
              className='btn-unfilled h-35'
              
              onClick={() =>
                navigate('/jive/promoCodeForm', {
                  state: {
                    record: promoCodeDetails,
                    formType: 'update'
                  }
                })
              }
            >
              <img
                src={Theme.icons.icn_edit}
                alt='icn_user_add'
                style={{ height: '15px' }}
              />
              Edit
            </CButton>
            )}
          </div>
          <div
            style={{
              backgroundColor: '#F3F3F3',
              padding: '24px',
              borderRadius: '16px'
            }}
          >
            <div className='row mt-4'>
              <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('Promo Code Name')}</p>
                <p className='detail-value'>
                  {promoCodeDetails?.promoCodeName}
                </p>
              </div>
              <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('Discount Percentage')}</p>
                <p className='detail-value'>
                  {promoCodeDetails?.discountValue} %
                </p>
              </div>
            </div>
            <div className='row '>
              <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('Promo Code Type')}</p>
                <p className='detail-value'>
                  {promoCodeDetails?.promoCodeType}
                </p>
              </div>
              <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('Usage Limit')}</p>
                <p className='detail-value'>{promoCodeDetails?.usageLimit}</p>
                {/* <p className='detail-value'>{promoCodeDetails?.usageLimit > 1 ? promoCodeDetails?.usageLimit +' Days' : promoCodeDetails?.usageLimit + ' Day'}</p> */}
              </div>
            </div>
            <div className='row '>
              {promoCodeDetails?.promoCodeType == 'Flat' && (
                <>
                <div className='col-lg-3'>
                  <p className='detail-label m-0'>{t('Associated Vendor')}</p>
                  <p className='detail-value'>
                    {promoCodeDetails?.vendorName}
                  </p>
                </div>
                 <div className='col-lg-3'>
                 <p className='detail-label m-0'>{t('Service Name')}</p>
                 <p className='detail-value'>
                   {promoCodeDetails?.serviceName}
                 </p>
               </div>
               </>
              )}
            </div>
            <div className='row '>
              <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('Validity Start Date')}</p>
                <p className='detail-value'>
                  {dayjs(promoCodeDetails?.startTime)?.format('DD.MM.YYYY')}
                </p>
              </div>
              <div className='col-lg-3'>
                <p className='detail-label m-0'>{t('Validity End Date')}</p>
                <p className='detail-value'>
                  {dayjs(promoCodeDetails?.endTime)?.format('DD.MM.YYYY')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AlertModal
        visible={isModalVisible}
        message={t(
          `Are you sure you want to ${
            promoCodeDetails?.isActive ? 'deactivate' : 'activate'
          } this Promo Code?`
        )}
        onDelete={handleModalOk}
        onCancel={handleModalCancel}
      />
      <CustomAlert
        message={alert.message}
        type={alert.type as 'success' | 'info' | 'warning' | 'error'}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </>
  )
}

export default PromoCodeView
