import React, { useEffect, useState } from 'react'
import { Form, Input, Row, Col, Spin } from 'antd'
import CInput from '../../../../components/common/CInput'
import { Theme } from '../../../../Theme'
import CButton from '../../../../components/common/CButton'
import { useMutation, useQuery } from 'react-query'
import CustomAlert from '../../../../components/common/CAlert'
import SuccessModal from '../../../../components/common/SuccessModal'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { addEventType, addExpiryTime, getEventTypeById } from '../../../../services/lov'

const AddExpiryTime: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { type, id } = useParams()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isSuccess, setIsSuccess] = useState(false)
  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })

  const getTitle = () => {
    if (type == 'view' && id != 'add') {
      return 'Event Details'
    }
    return type == 'edit' ? 'Edit Expiry Time' : 'Add Expiry Time'
  }

  const record = location.state?.record

  console.log('location.state?.record===>>>', location.state?.record);

  const { mutate, isLoading } = useMutation(addExpiryTime, {
    onSuccess: response => {
      if (response.succeeded) {
        setIsSuccess(true)
      } else {
        setAlert({ message: response.message, type: 'error', visible: true })
      }
    },
    onError: (error: any) => {
      setAlert({
        message: error?.message || 'An error occurred. Please try again.',
        type: 'error',
        visible: true
      })
    }
  })

  /* const { mutate: fetchEventType, isLoading: isFetchingEvent } = useMutation(
        (id: any) => getEventTypeById(id), 
        {
          onSuccess: (response) => {
            if (response.succeeded) {
              
              setEventTypeDetails(response.data); 
            } else {
              setAlert({ message: response.message, type: 'error', visible: true });
            }
          },
          onError: (error: any) => {
            setAlert({
              message: error?.message || 'An error occurred. Please try again.',
              type: 'error',
              visible: true,
            });
          },
        }
      );
      

      useEffect(() => {
        if (type !== 'add' && id !== 'new') {
          fetchEventType(id);
        }
      }, [type, id, fetchEventType]);
      */

  useEffect(() => {
    if (location.state?.record) {
      form.setFieldsValue({
        expiryTimeInHour: location.state.record.expiryTimeInHour
      })
    }
  }, [location.state])

  const handleSave = (values: any) => {
    const obj = {
      ...values,
      ...(record && { id: record.id })
    }
    mutate(obj)
  }

  return (
    <>
      <div style={{ padding: '20px', color: Theme.colors.black }}>
        <div className='d-flex align-items-center mb-4'>
          <img
            src={Theme.icons.icn_arrow_back}
            className='cursor-pointer'
            alt='icn_user_add'
            style={{ height: '20px' }}
            onClick={() => navigate(-1)}
          />
          <p className='list-heading m-0 mx-2'>{t('Expiry Time')}</p>
        </div>
        {type == 'view' && id != 'add' && (
          <div
            style={{
              backgroundColor: Theme.colors.white,
              padding: '24px',
              borderRadius: '16px'
            }}
          >
            <p className='user-name'>{getTitle()}</p>
            <div className='view-container'>
              <div className='view-container-content'>
                <div>
                  <p className='label m-0'>{t('Expiry Time')}</p>
                  <p className='detail m-0'>{record?.name}</p>
                </div>
                <button
                  className='btn-unfilled'
                  onClick={() =>
                    navigate(`/jive/eventType/edit/${id}`, {
                      state: { record: record }
                    })
                  }
                >
                  <img
                    src={Theme.icons.icn_edit}
                    alt='icn_user_add'
                    style={{ height: '15px' }}
                  />
                  Edit
                </button>
              </div>
            </div>
          </div>
        )}
        {type != 'view' && (
          <div>
            <Form
              form={form}
              layout='vertical'
              onFinish={handleSave}
              style={{
                backgroundColor: Theme.colors.white,
                padding: '24px',
                borderRadius: '16px'
              }}
            >
              <p className='user-name'>{getTitle()}</p>

              <>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      name='expiryTimeInHour'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter the expiry time'
                        }
                      ]}
                    >
                      <CInput
                        label={t('Expiry Time')}
                        placeholder={t('Enter Event Type')}
                        type='number'
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8} style={{ marginTop: '15px' }}>
                  <Col span={4}>
                    <CButton
                      loading={isLoading}
                      style={{ width: '100%' }}
                      htmlType='submit'
                    >
                      {type == 'edit' ? 'Update' : 'Save'}
                    </CButton>
                  </Col>
                </Row>
              </>
            </Form>
            <SuccessModal
              visible={isSuccess}
              message={
                type == 'edit'
                  ? 'Expiry time updated successfully'
                  : 'New Expiry Time added successfully'
              }
              onOk={() => navigate('/jive/eventPolicyManagementList')}
            />
            <CustomAlert
              message={alert.message}
              type={alert.type as 'success' | 'info' | 'warning' | 'error'}
              visible={alert.visible}
              onClose={() => setAlert({ ...alert, visible: false })}
              duration={3000}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default AddExpiryTime;
