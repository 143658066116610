import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import CModal from './CModal';
import CButton from './CButton';
import { Theme } from '../../Theme';
import { useTranslation } from 'react-i18next';

interface SuccessModalProps {
  visible: boolean;
  onOk: () => void;
  onCancel?: () => void;
  message?: string;
}

const 
SuccessModal: React.FC<SuccessModalProps> = ({ visible, onOk, message, onCancel }) => {
  const { t } = useTranslation();
  return (
    <CModal
      visible={visible}
      onOk={onOk}
      footer={null} // To use custom footer buttons
      centered
      title={null}
      className='success-modal'
      onCancel={onCancel}
    >
      <div style={{ textAlign: 'center' }}>
      <img src={Theme.icons.icn_success_modal} alt="logo" className="modal-logo" />
        <h2 className='user-name mt-2'>{t('success_modal_heading')}</h2>
        <p className='login-des'>{message}</p>
        <CButton
          onClick={onOk}
          style={{ width: '100%'}}
        >
          {t("Continue")}
        </CButton>
      </div>
    </CModal>
  );
};

export default SuccessModal;
