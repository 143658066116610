import { ColumnsType } from 'antd/es/table'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Theme } from '../../../Theme'
import CustomAlert from '../../../components/common/CAlert'
import CInput from '../../../components/common/CInput'
import CTable from '../../../components/common/CTable'
import { useEffect, useState } from 'react'
import { get } from '../../../services/booking'
import Status from '../../../components/common/Status'
interface VendorBookingProps {
  status: string
}
const VendorBookings: React.FC<VendorBookingProps> = ({
  status: defaultStatus
}) => {
  const [alert, setAlert] = useState<{
    message: string
    type: 'success' | 'error'
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })

  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate()
  const { t } = useTranslation()
  const status = searchParams.get('Status') || defaultStatus
  const pageNumber = Number(searchParams.get('PageNumber')) || 1
  const pageSize = Number(searchParams.get('PageSize')) || 10
  const search = searchParams.get('Search') || ''
  const sortOrder = searchParams.get('SortOrder') || 'z'

  useEffect(() => {
    setSearchParams({
      Status: defaultStatus ? defaultStatus : status,
      PageNumber: '1',
      PageSize: searchParams.get('PageSize') || '10'
    })
  }, [defaultStatus])

  const handleSearchChange = _.debounce((e: any) => {
    setSearchParams(
      {
        Status: status,
        PageNumber: '1',
        PageSize: pageSize.toString(),
        Search: e.target.value
      },
      { replace: true }
    )
  }, 1000)

  const handleSorting = () => {
    const newSortOrder = sortOrder === 'z' ? 'a' : 'z'
    setSearchParams(
      {
        Status: status,
        PageNumber: pageNumber.toString(),
        PageSize: pageSize.toString(),
        Search: search,
        SortOrder: newSortOrder
      },
      { replace: true }
    )
  }
  const getVendorName = (vendors: any[]) => {
    const firstVendorName = vendors[0].vendorName
    const uniqueVendorIds = new Set()
    const vendorNames: any[] = []
    vendors.forEach(vendor => {
      uniqueVendorIds.add(vendor.vendorId)
      vendorNames.push(vendor.vendorName)
    })

    if (uniqueVendorIds.size === 1) {
      return firstVendorName
    } else {
      // const uniqueNames = Array.from(new Set(vendorNames)).join(' & ')
      return `${firstVendorName} & Other`
    }
  }

  const columns: ColumnsType = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: t('Customer Name'),
      dataIndex: 'customerName',
      key: 'customerName'
    },
    {
      title: t('Phone Number'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },
    {
      title: t('Vendor Name'),
      key: 'vendorName',
      render: (text, record) => (
        <>
          {record?.serviceVendorNames.length > 0 && (
            <div> {getVendorName(record?.serviceVendorNames)}</div>
          )}
        </>
      )
    },

    {
      title: t('Status'),
      key: 'status',
      render: (text, record) => {
        console.log('record===>>>', text, record)
        return (
          <div>
            <Status status={record.status} />
          </div>
        )
      }
    },
    {
      title: t('action'),
      render: (text, record) => (
        <div className='d-flex  align-items-center'>
          <img
            src={Theme.icons.icn_view_list}
            alt='icn_user_add'
            style={{ height: '20px', cursor: 'pointer' }}
            onClick={() =>
              navigate(`/jive/customEvent/view/${record?.id}`, {
                state: { record }
              })
            }
          />
        </div>
      ),
      key: 'action'
    }
  ]

  const handleTableChange = (page: number, pageSize: number) => {
    setSearchParams(
      {
        Status: status,
        PageNumber: page.toString(),
        PageSize: pageSize.toString()
      },
      { replace: true }
    )
  }

  const { data, error, isLoading } = useQuery(
    [status, pageNumber, pageSize, search, sortOrder],
    () =>
      get('AdminCustomEvent/GetAllCustomEvent', {
        Status: status,
        PageNumber: pageNumber,
        PageSize: pageSize,
        Search: search,
        SortColumn: 'Id',
        SortOrder: sortOrder
      }),
    {
      // keepPreviousData: true,
      onError: (err: any) => {
        setAlert({
          message: err?.message || t('An error occurred'),
          type: 'error',
          visible: true
        })
      }
    }
  )

  return (
    <>
      {/* <p className='page-heading'>{t('Booking List')}</p> */}
      <div className='d-flex justify-content-between align-items-center'>
        <p className='m-0 list-heading'>{t('Booking List')}</p>
        <div className='d-flex justify-content-between'>
          <CInput
            prefix={
              <img
                alt=''
                src={Theme.icons.icn_search}
                style={{ height: '16px' }}
              />
            }
            className='searchInput'
            placeholder={t('Search')}
            onChange={handleSearchChange}
          />
          <div
            className='calendar-div'
            style={{ backgroundColor: '#D0C64F' }}
            onClick={() => handleSorting()}
          >
            <img
              src={Theme.icons.icn_sorting}
              alt='icn_user_add'
              style={{
                height: '20px',
                transform:
                  sortOrder === 'z' ? 'rotate(0deg)' : 'rotate(180deg)',
                transition: 'transform 0.3s ease-in-out'
              }}
            />
          </div>
        </div>
      </div>
      <CTable
        footer='End of Table'
        columns={columns}
        dataSource={data?.data}
        pagination={{
          showSizeChanger: false,
          showQuickJumper: false,
          current: pageNumber,
          pageSize: pageSize,
          total: data?.totalRecords,
          onChange: handleTableChange,
          showTotal: (total: number, range: [number, number]) => (
            <p>{`Showing ${range[0]} - ${range[1]} of ${total} Results`}</p>
          ),
          itemRender: (page, type, originalElement) => {
            if (type === 'prev') {
              return <p className='detail-label'>{`< Prev`}</p>
            }
            if (type === 'next') {
              return <p className='detail-label'>{`Next >`}</p>
            }
            return originalElement
          }
        }}
        loading={isLoading}
      />
      <CustomAlert
        message={alert.message}
        type={alert.type}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </>
  )
}
export default VendorBookings
