import React, { useEffect, useState } from 'react'
import CustomTabs from '../../components/common/CTabs'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import OrderList from './Order/OrderList'
import CustomerList from './Customer/CustomerList'
// import EventList from './Events/EventList'
// import CategoriesList from './Categories/CategoriesList'
// import TermsAndConditions from './TermsAndConditions/TermsAndConditions'
// import CancellationPolicy from './CancellationPolicy/CancellationPolicy'
// import CountryList from './Country/Countries'

const tabs = [
  { key: 'order', label: 'Order Report' },
  { key: 'customer', label: 'Customer Report' },
]

const ReportList: React.FC = () => {
  const [activeKey, setActiveKey] = useState('order')
  const navigate = useNavigate()
  const { t } = useTranslation()
  const handleTabChange = (key: string) => {
    setActiveKey(key);
    localStorage.setItem('active',key);
  }

  useEffect(() => {
    const storedActiveTab = localStorage.getItem('active');
    if (storedActiveTab) {
      setActiveKey(storedActiveTab);
    }
  }, []);

  return (
    <>
      <p className='page-heading'>{t('Reports')}</p>
      <CustomTabs
        tabs={tabs}
        activeKey={activeKey}
        onTabChange={handleTabChange}
      />
      {activeKey === 'order' && <OrderList />}
      {activeKey === 'customer' && <CustomerList />}
      {/* {activeKey === 'category' && <CategoriesList />}
      {activeKey === 'termsAndCondition' && <TermsAndConditions />}
      {activeKey === 'cancellationPolicy' && <CancellationPolicy />} */}
    </>
  )
}

export default ReportList;
