import instance from "./api/api_instance";

export const getAllAdminOrderReports = async (credentials: object) => {
    const response = await instance.get("AdminReportsController/GetAdminOrderReports", {
      params: credentials,
    });
    return response.data;
  };

  export const getOrderReportExportToExcel = async (credentials: object) => {
    const response = await instance.get("AdminReportsController/AdminOrderReportExportToExcel", {
      params: credentials,
      responseType: 'blob',
    });
    return response.data;
  };

  export const getAllCustomerOrderReports = async (credentials: object) => {
    const response = await instance.get("AdminReportsController/GetCustomerOrderReports", {
      params: credentials,
    });
    return response.data;
  };

  export const getCustomerReportExportToExcel = async (credentials: object) => {
    const response = await instance.get("AdminReportsController/CustomerOrderReportExportToExcel", {
      params: credentials,
      responseType: 'blob',
    });
    return response.data;
  };