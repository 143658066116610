import { Col, DatePicker, DatePickerProps, Form, Row } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { getVendorsList } from '../../services/booking'
import { get, post } from '../../services/api/apiService'
import { Theme } from '../../Theme'
import CInput from '../../components/common/CInput'
import CustomSelect from '../../components/common/CSelect'
import dayjs from 'dayjs'
import CButton from '../../components/common/CButton'
import SuccessModal from '../../components/common/SuccessModal'
const promotypes = [
  { value: 'Flat', label: 'Flat' },
  { value: 'Generic', label: 'Generic' }
]
const PromoCodeForm: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isSuccess, setIsSuccess] = useState(false)
  const [showVendors, setShowVendors] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [vendorServiceList, setVendorServiceList] = useState<any[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<{ [key: string]: any }>(
    {}
  )
  const [selectedvendorService, setSelectedvendorService] = useState<{ [key: string]: any }>({})

  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })

  const {
    data: VendorList,
    error: VendorListError,
    isLoading: VenodLoading
  } = useQuery('vendorList', getVendorsList)
  const getData = (val: any) => {
    console.log('val', val)

    if (!val) return [] // Return an empty array if data is undefined
    return val.data.map((x: any) => ({
      value: x.id,
      label: x.name
        ? x.name
        : x.contactPersonName
        ? x.contactPersonName
        : x.serviceName
    }))
  }

  const {
    mutate: fetchCustomEventDetails,
    isLoading: isFetchingCustomEventDetails
} = useMutation(
    (id: any) => get('AdminService/GetServiceByVendorId', { vendorId: id }),
    {
        onSuccess: response => {
            if (response.succeeded) {
                setVendorServiceList(response.data);
            } else {
                setAlert({ message: response.message, type: 'error', visible: true })
            }
        },
        onError: (error: any) => {
            setAlert({
                message: error?.message || 'An error occurred. Please try again.',
                type: 'error',
                visible: true
            })
        }
    }
)

  const onChangePromoType = async (type: any) => {
    setShowVendors(type == 'Flat' ? true : false)
  }

  const handleSave = async () => {
    const data = form.getFieldsValue()
    // const { serviceRequestDate, ...newObj } = data
    setSubmitLoading(true)
    const obj = {
      ...data,
      discountType: 'Precentage'
      // serviceRequestDate: serviceRequestDate.format('YYYY-MM-DD')
    }
    if (record) {
      obj['id'] = record.id
    }
    console.log('Form submitted', obj)

    setSubmitLoading(false)

    let response = await post('AdminPromoCode/AddUpdate', obj)
    if (response.succeeded) {
      setIsSuccess(true)
    } else {
      setAlert({ message: response.message, type: 'error', visible: true })
    }
  }

  // const onChangeVendor = async (vendorId: any) => {
  //   let vn = VendorList.data.find(
  //     (vendor: { phoneNumber: any; email: any; id: any }) =>
  //       vendor.id == vendorId
  //   )
  //   setSelectedVendor(vn)
  // }

  const onChangeVendor = async (vendorId: any) => {
    form.setFieldsValue({
        serviceId: undefined
    })
    fetchCustomEventDetails(vendorId);
    let vn = VendorList.data.find((vendor: { phoneNumber: any, email: any, id: any }) => (vendor.id == vendorId))
    setSelectedVendor(vn);
};

const onChangeVendorService = (serviceId: any) => {
  let sv = vendorServiceList.find(service => (service.id == serviceId))
  setSelectedvendorService(sv);
  console.log('sv===>>>', sv);
  form.setFieldsValue({
      description: sv.description,
      categoryName : sv?.categoryName
  })
}

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {}
  const { record, formType } = location.state
  useEffect(() => {
    if (record) {
      if (record != undefined) {
        // setSelectedvendorService({label : record.serviceId,value:record?.serviceName})
        fetchCustomEventDetails(record?.vendorId);
        form.setFieldsValue({
          ...record,
          startTime: dayjs(record?.startTime),
          endTime: dayjs(record?.endTime)
        })
      }
    }
    onChangePromoType(record?.promoCodeType)
  }, [])

  return (
    <>
      <div style={{ padding: '20px', color: Theme.colors.black }}>
        <div className='d-flex align-items-center mb-4'>
          <img
            src={Theme.icons.icn_arrow_back}
            className='cursor-pointer'
            alt='icn_user_add'
            style={{ height: '20px' }}
            onClick={() => navigate(-1)}
          />
          <p className='list-heading m-0 mx-2'>{'Promo Code'}</p>
        </div>
        <Form
          form={form}
          layout='vertical'
          onFinish={handleSave}
          style={{
            backgroundColor: Theme.colors.white,
            padding: '24px',
            borderRadius: '16px'
          }}
        >
          <p className='user-name'>
            {`${formType == 'create' ? 'Create' : 'Update'} Promo Code`}
          </p>
          <>
            <div className='row' style={{ width: '90%' }}>
              <div className='col-lg-4'>
                <Form.Item
                  name='promoCodeName'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the promo code name')
                    }
                  ]}
                >
                  <CInput
                    label={t('Promo Code Name')}
                    placeholder={t('Enter Promo Code Name')}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-4'>
                <Form.Item
                  name='discountValue'
                  rules={[
                    {
                      required: true,
                      message: t(
                        'Discount percentage must be between 1 and 100'
                      ),
                      validator: (_, value) => {
                        if (
                          !value ||
                          (Number(value) >= 1 && Number(value) <= 100)
                        ) {
                          return Promise.resolve()
                        } else {
                          return Promise.reject(
                            t('Discount percentage must be between 1 and 100')
                          )
                        }
                      }
                    }
                  ]}
                >
                  <CInput
                    type='number'
                    label={t('Discount Percentage')}
                    placeholder={t('Enter Discount Percentage')}
                  />
                </Form.Item>
              </div>
              {/* <div className='col-lg-4'>
                <Form.Item
                  name='discountType'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the discount type')
                    }
                  ]}
                >
                  <CInput
                    disabled
                    label={t('Discount Type')}
                    placeholder={t('Enter Discount Type')}
                  />
                </Form.Item>
              </div> */}
            </div>

            <div className='row' style={{ width: '90%' }}>
              <div className='col-lg-4'>
                <Form.Item
                  name='usageLimit'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the usage limit'),
                      validator: (_, value) => {
                        if (!value || /^[0-9]*$/.test(value)) {
                          return Promise.resolve()
                        } else {
                          return Promise.reject(t('price_error'))
                        }
                      }
                    }
                  ]}
                >
                  <CInput
                    type='number'
                    label={t('Usage Limit')}
                    placeholder={t('Enter Usage Limit')}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-4'>
                <Form.Item
                  name='promoCodeType'
                  rules={[
                    {
                      required: true,
                      message: t('Please selec the promo code type')
                    }
                  ]}
                >
                  <CustomSelect
                    label={t('Promo Code Type')}
                    options={promotypes}
                    placeholder={t('Select promo code type')}
                    onChange={value => onChangePromoType(value)}
                  />
                </Form.Item>
              </div>
            </div>
            {showVendors && (
              <div className='row' style={{ width: '90%' }}>
                <div className='col-lg-4'>
                  <Form.Item
                    name='vendorId'
                    rules={[
                      { required: true, message: t('Please choose a vendor') }
                    ]}
                  >
                    <CustomSelect
                      label={t('Associated Vendor')}
                      options={getData(VendorList)}
                      value={selectedVendor.id}
                      onChange={value => onChangeVendor(value)}
                      placeholder={t('Choose Vendor')}
                    />
                  </Form.Item>
                </div>
                <div className='col-lg-4'>
                  <Form.Item
                    name='serviceId'
                    rules={[
                      { required: true, message: t('Please choose a service') }
                    ]}
                  >
                    <CustomSelect
                      label={t('Service')}
                      options={
                        vendorServiceList.length > 0
                          ? getData({ data: vendorServiceList })
                          : []
                      }
                      value={selectedvendorService.value}
                      onChange={value => onChangeVendorService(value)}
                      placeholder={t('Choose Service')}
                    />
                  </Form.Item>
                </div>
              </div>
            )}
            <div className='row' style={{ width: '90%' }}>
              <div className='col-lg-4'>
                <label
                  className='inputfield-label'
                  style={{ display: 'block', marginBottom: '5px' }}
                >
                  {t('Validity Start Date')}
                </label>
                <Form.Item
                  name='startTime'
                  rules={[
                    {
                      required: true,
                      message: t('Enter Validity Start Date')
                    }
                  ]}
                >
                  <DatePicker
                    disabledDate={current =>
                      current && current.isBefore(dayjs().startOf('day'))
                    }
                    className='inputfield w-100'
                    onChange={onChange}
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <label
                  className='inputfield-label'
                  style={{ display: 'block', marginBottom: '5px' }}
                >
                  {t('Validity End Date')}
                </label>
                <Form.Item
                  name='endTime'
                  rules={[
                    {
                      required: true,
                      message: t('Enter Validity End Date')
                    }
                  ]}
                >
                  <DatePicker
                    disabledDate={current =>
                      current && current.isBefore(dayjs().startOf('day'))
                    }
                    className='inputfield w-100'
                    onChange={onChange}
                  />
                </Form.Item>
              </div>
            </div>
          </>
          <Row gutter={8} style={{ marginTop: '15px' }}>
            <Col span={4}>
              <CButton className='cancelBtn' onClick={() => navigate(-1)}>
                Cancel
              </CButton>
            </Col>
            <Col span={4}>
              <CButton
                loading={submitLoading}
                style={{ width: '100%' }}
                htmlType='submit'
              >
                Submit
              </CButton>
            </Col>
          </Row>
        </Form>
        <SuccessModal
          visible={isSuccess}
          message={`Promo code ${
            formType == 'create' ? 'created' : 'updated'
          } successfully.`}
          onOk={() => navigate(`/jive/promoCodesList`)}
        />
      </div>
    </>
  )
}

export default PromoCodeForm
